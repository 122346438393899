define('foogi-ember-client/pods/components/calendar-display/full/cal-header/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var not = computed.not;
  var and = computed.and;
  exports['default'] = _ember['default'].Component.extend({
    //Interface
    classNames: ['calendar-header'],
    calendar: null,
    selection: null,
    week: _ember['default'].A(),

    firstDay: computed('week', function () {
      return moment(this.get('week').get('firstObject.date'));
    }),
    lastDay: computed('week', function () {
      return moment(this.get('week').get('lastObject.date'));
    }),
    _firstDayFormat: function _firstDayFormat(format) {
      return this.get('firstDay').format(format);
    },
    _lastDayFormat: function _lastDayFormat(format) {
      return this.get('lastDay').format(format);
    },

    weekContainedInOneMonth: computed('firstDay', 'lastDay', function () {
      return this._firstDayFormat("MMM") === this._lastDayFormat("MMM");
    }),
    weekContainedInOneYear: computed('firstDay', 'lastDay', function () {
      return this._firstDayFormat("YYYY") === this._lastDayFormat("YYYY");
    }),

    weekStartDayNumber: computed('firstDay', function () {
      return this._firstDayFormat('D');
    }),
    weekStartMonthName: computed('firstDay', function () {
      return this._firstDayFormat('MMM');
    }),
    weekStartYearNumber: computed('firstDay', function () {
      return this._firstDayFormat('YYYY');
    }),

    weekEndDayNumber: computed('lastDay', function () {
      return this._lastDayFormat('D');
    }),
    weekEndMonthName: computed('lastDay', function () {
      return this._lastDayFormat('MMM');
    }),
    weekEndYearNumber: computed('lastDay', function () {
      return this._lastDayFormat('YYYY');
    }),

    displayAsReceiver: not('displayAsSender'),

    showTimezoneButton: and('displayAsReceiver', 'meetingOnline'),

    actions: {
      jumpWeekPrev: function jumpWeekPrev() {
        this.sendAction('jumpWeekPrev');
      },
      jumpWeekNext: function jumpWeekNext() {
        this.sendAction('jumpWeekNext');
      },
      authenticate: function authenticate(provider) {
        this.sendAction('authenticate', provider);
      },
      toggleCalendar: function toggleCalendar(calendar, value) {
        this.sendAction('toggleCalendar', calendar, value);
        // this.sendAction('toggleCalendar', account, calendar, value);
      }
    }
  });
});