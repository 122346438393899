define('foogi-ember-client/pods/request/edit/wizard/availability/route', ['exports', 'ember', 'foogi-ember-client/mixins/wizard-navigation'], function (exports, _ember, _foogiEmberClientMixinsWizardNavigation) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsWizardNavigation['default'], {
    afterModel: function afterModel(model) {
      model.set('optionsSelectedAvailability', true);
    },
    actions: {
      updateTime: function updateTime(value) {
        var req = this.modelFor(this.routeName);
        req.set('availabilityTimeMode', value);
        req.save();
      },
      updateRange: function updateRange(value) {
        var req = this.modelFor(this.routeName);
        req.set('availabilityRangeMode', value);
        req.save();
      }
    }
  });
});