define('foogi-ember-client/utils/moment-round-to', ['exports'], function (exports) {
  exports['default'] = roundTo;
  /* global moment */

  function roundTo(momentTime, unit, offset, midpoint) {
    unit = moment.normalizeUnits(unit);
    offset = offset || 1;
    var value = momentTime.get(unit);

    switch (midpoint) {
      case 'up':
        value = Math.ceil(value / offset);
        break;
      case 'down':
        value = Math.floor(value / offset);
        break;
      default:
        value = Math.round(value / offset);
        break;
    }

    return momentTime.set(unit, value * offset);
  }
});