define('foogi-ember-client/pods/components/confirmation-card/section-top/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirmation-card-section-top'],
    dropDownLabel: null,
    fullWidth: _ember['default'].computed.empty('dropDownLabel'),
    actions: {
      cardClick: function cardClick() {
        this.sendAction('cardClick', this.get('card'));
      },
      dropDownClick: function dropDownClick() {
        this.sendAction('dropDownClick', this.get('card'));
      }
    }
  });
});