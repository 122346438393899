define('foogi-ember-client/pods/account/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    //Attrs
    email: attr('string'),
    provider: attr('string'),
    provider_uid: attr('string'),
    enabled: attr('boolean'),
    calendars: hasMany('calendars')

  });
});