define("foogi-ember-client/templates/components/loading-spinning-bubbles", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/templates/components/loading-spinning-bubbles.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(45 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.125s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(90 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.25s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(135 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.375s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(180 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.5s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(225 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.625s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(270 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.75s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(315 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.875s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("circle");
        dom.setAttribute(el2, "transform", "rotate(180 16 16)");
        dom.setAttribute(el2, "cx", "16");
        dom.setAttribute(el2, "cy", "3");
        dom.setAttribute(el2, "r", "0");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "r");
        dom.setAttribute(el3, "values", "0;3;0;0");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.5s");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "loadingSvgSize", ["loc", [null, [1, 68], [1, 82]]]]], ["attribute", "height", ["get", "loadingSvgSize", ["loc", [null, [1, 94], [1, 108]]]]], ["attribute", "fill", ["get", "loadingSvgColor", ["loc", [null, [1, 118], [1, 133]]]]]],
      locals: [],
      templates: []
    };
  })());
});