define('foogi-ember-client/pods/request/edit/availability/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-edit-return-to'], function (exports, _ember, _foogiEmberClientMixinsRequestEditReturnTo) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestEditReturnTo['default'], {
    actions: {
      updateTime: function updateTime(value) {
        var req = this.modelFor(this.routeName);
        req.set('availabilityTimeMode', value);
      },
      updateRange: function updateRange(value) {
        var req = this.modelFor(this.routeName);
        req.set('availabilityRangeMode', value);
      }
    }
  });
});