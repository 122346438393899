define('foogi-ember-client/authenticators/hybrid-oauth2', ['exports', 'ember', 'ember-simple-auth/authenticators/oauth2-password-grant', 'foogi-ember-client/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _foogiEmberClientConfigEnvironment) {
  var RSVP = _ember['default'].RSVP;
  var isEmpty = _ember['default'].isEmpty;
  var run = _ember['default'].run;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var assert = _ember['default'].assert;
  var isPresent = _ember['default'].isPresent;

  var assign = _ember['default'].assign || _ember['default'].merge;

  var providerMap = {
    'outlook-oauth2-custom': 'outlook',
    'google-oauth2-custom': 'google',
    'ms-graph-oauth2-custom': 'ms_graph'
  };

  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    serverTokenEndpoint: _foogiEmberClientConfigEnvironment['default'].apiUrl + '/v1/tokens',
    torii: inject.service('torii'),
    session: inject.service(),

    resetWorking: function resetWorking() {
      this.get('session').set('isWorking', false);
      this.get('session').set('isAuthenticating', false);
      this.get('session').set('isAuthorizing', false);
    },

    authenticate: function authenticate(provider, options) {
      var _this = this;

      // This auth method is two step:
      // 1. use torii to handle login from an external authentication source / identity provider (google, ms etc)
      // 2a take the response from the external provider, hand it off to our backend in exchange for auth tokens to our backend
      // 2b the backend will give us tokens that authorize us with our backend and it will in-turn be authorized against the identity provider
      this.get('session').set('isWorking', true);
      this._assertToriiIsPresent();

      this.get('session').set('isAuthenticating', true);
      return new RSVP.Promise(function (resolve, reject) {
        // Step 1

        _this.get('torii').open(provider).then(function (response) {
          if (!(response.authorizationToken || response.authorizationCode)) {
            reject(new Error("Response didn't have a valid authorization Code or Token"));
          }

          var redirectUri = _foogiEmberClientConfigEnvironment['default'].hostUrl;
          var serverTokenEndpoint = _this.get('serverTokenEndpoint');

          // Get the appropriate provider to send to the backend from the torii provider name
          var apiProviderName = providerMap[provider]; // potential injection vector

          // Prepare the torii response to then feed to our oauth backend (between steps 1->2)
          var code = response.authorizationCode || response.authorizationToken['code'];
          var data = {
            code: code,
            provider: apiProviderName,
            application_name: 'ember_v1',
            redirect_uri: redirectUri
          };

          _this.get('session').set('isAuthorizing', true);
          _this.get('session').set('isAuthenticating', false);
          // Step 2a - Do the oauth request to our backend. This is a fairly vanilla oauth2 code request
          _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
            run(function () {
              // Step 2b - store the tokens we get back so we're logged in to our backend
              var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);
              _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
              if (!isEmpty(expiresAt)) {
                response = assign(response, {
                  'expires_at': expiresAt
                });
              }
              resolve(response);
            });

            _this.resetWorking();
          }, function (xhr) {
            run(null, reject, xhr.responseJSON || xhr.responseText);
            _this.resetWorking();
          });
        }, function () {
          _this.resetWorking();
          return reject;
        });
      });
    },

    _assertToriiIsPresent: function _assertToriiIsPresent() {
      // from 'ember-simple-auth/authenticators/torii'
      var torii = this.get('torii');
      assert('You are trying to use the torii authenticator but torii is not available. Inject torii into the authenticator with "torii: Ember.inject.service()".', isPresent(torii));
    }
  });
});