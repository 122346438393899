define('foogi-ember-client/pods/index/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'foogi-ember-client/mixins/google-maps-actions', 'foogi-ember-client/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _foogiEmberClientMixinsGoogleMapsActions, _foogiEmberClientConfigEnvironment) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _foogiEmberClientMixinsGoogleMapsActions['default'], {
    session: inject.service('session'),
    currentUser: inject.service('session-user'),
    ajax: inject.service(),

    model: function model() {
      return this.store.findAll('timeRequest');
    },

    afterModel: function afterModel(model, transition) {
      var _this = this;

      this.get('currentUser.me').then(function (user) {
        if (user) {
          var profileCom = user.get('defaultProfileCompleted');
          if (!profileCom) {
            _this.transitionTo('profile.edit', 'default');
          }
        }
      });
    },

    handleNew: function handleNew() {
      if (this.get('currentUser.me.defaultProfileCompleted')) {
        this.transitionTo('new');
      } else {
        this.transitionTo('profile.edit', 'default');
      }
    },

    renderTemplate: function renderTemplate(controller, model) {
      this._super(controller, model);
      //this.render('profile', {
      //  outlet: 'sidebar',
      //  into: 'application'
      //});
    },

    getModelForLocation: function getModelForLocation() {
      // Overriding the mixin
      return this.controllerFor('index').get('defaultProfile');
    },

    actions: {
      clickNew: function clickNew() {
        this.segment.trackEvent('Dashboard: Button - New meeting');
        this.handleNew();
      },

      cancelRequest: function cancelRequest(token) {
        this.store.find('timeRequest', token).then(function (req) {
          req.destroyRecord();
        });
      },
      show: function show(token) {
        this.transitionTo('request.show', token);
      },
      edit: function edit(token) {
        this.transitionTo('request.edit.wizard', token);
      },
      hide: function hide(token) {
        var _this2 = this;

        var urlString = _foogiEmberClientConfigEnvironment['default'].API.HOST + '/' + _foogiEmberClientConfigEnvironment['default'].API_NAMESPACE + '/time_requests/' + token + '/archive';

        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          _this2.get('ajax').request(urlString, {
            method: "POST",
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader(headerName, headerValue);
            }
          }).then(function () {
            _this2.store.find('timeRequest', token).then(function (req) {
              _this2.store.unloadRecord(req);
            });
          });
        });
      },
      resend: function resend(token) {
        this.transitionTo('request.confirm', token);
      },
      saveProfile: function saveProfile() {
        var _this3 = this;

        var profile = this.modelFor(this.routeName);
        profile.save().then(function () {
          _this3.get('currentUser').reload();
        });
      }
    }
  });
});