define('foogi-ember-client/pods/components/calendar-options-dropdown/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['btn-group calendar-options-dropdown'],
    user: null,
    notifications: false,
    displayTimezone: null,
    requestTimezone: null,
    meetingEnd: null,
    hostName: null,
    receiverDisplayName: null,
    placedTimeCoOrds: null,
    activeWeek: null,
    calendarOptionsOpen: false,
    authenticated: false,

    actions: {
      toggleCalendar: function toggleCalendar(calendar, value) {
        this.sendAction('toggleCalendar', calendar, value);
        // this.sendAction('toggleCalendar', account, calendar, value);
      },
      authenticate: function authenticate(provider) {
        this.sendAction('authenticate', provider);
      },
      updateDuration: function updateDuration(value) {
        this.sendAction('updateDuration', value);
      }
    }

  });
});
/* global moment */
/* global jstz */