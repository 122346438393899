define("foogi-ember-client/features/-main", ["exports", "ember-feature-flags/features", "foogi-ember-client/config/environment"], function (exports, _emberFeatureFlagsFeatures, _foogiEmberClientConfigEnvironment) {
  exports["default"] = _emberFeatureFlagsFeatures["default"].extend({

    init: function init() {
      this._super.apply(this, arguments);

      if (this.application && !Ember.isNone(this.application.FEATURES)) {
        Ember.deprecate('[ember-feature-flags] Setting feature flags via `APP.FEATURES` is deprecated and will be removed.');
        this.setup(this.application.FEATURES);
      } else if (_foogiEmberClientConfigEnvironment["default"].featureFlags) {
        this.setup(_foogiEmberClientConfigEnvironment["default"].featureFlags);
      }
    }

  });
});