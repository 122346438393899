define('foogi-ember-client/pods/components/confirmation-card/section-top/card-icon/component', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['card-icon'],
    staticIcon: equal('type', 'static'),
    initials: equal('type', 'initials'),
    image: equal('type', 'image'),

    initialsContent: computed('content', function () {
      var content = this.get('content');
      if (_ember['default'].isBlank(content)) {
        return '?';
      }
      //var content = 'abc';
      return content.charAt(0).toUpperCase();
    })
  });
});