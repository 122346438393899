define('foogi-ember-client/pods/request/edit/invitee/controller', ['exports', 'ember', 'foogi-ember-client/mixins/request-user-session'], function (exports, _ember, _foogiEmberClientMixinsRequestUserSession) {
  var computed = _ember['default'].computed;
  var not = computed.not;
  var alias = computed.alias;
  exports['default'] = _ember['default'].Controller.extend(_foogiEmberClientMixinsRequestUserSession['default'], {
    pageTitle: computed('currentUser.me', 'model.id', function () {
      var req = this.get('model');
      return this.userIsOwner(req) ? 'Who will receive the meeting invitation?' : 'Update your details';
    }),
    request: alias('model'),
    isInvalid: not('model.validRecipient')
  });
});