define('foogi-ember-client/mixins/google-maps-actions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    getModelForLocation: function getModelForLocation() {
      return this.modelFor(this.routeName); //can be overridden if necessary
    },

    actions: {
      setMeetingLocation: function setMeetingLocation(place) {
        if (place.address_components) {
          var model = this.getModelForLocation();

          model.set('locPlaceId', place.place_id); // Required for backend address resolution
          model.set('locName', place.name); // Required for front-end display of the address
          _ember['default'].$('.pac-container').hide();
        }
      },

      clearLocation: function clearLocation() {
        var model = this.getModelForLocation();
        model.set('googlePlaceObject', null);
        model.set('locName', null);
        model.set('locPlaceId', null);
        model.set('locAddress', null);
        model.set('locLat', null);
        model.set('locLng', null);
        _ember['default'].$('.pac-container').hide();
      },

      pressedEnterKey: function pressedEnterKey() {
        if (_ember['default'].$('.pac-container .pac-item').length > 0) {
          // redisplay results container
          _ember['default'].$('.pac-container').show();
          // select the first option
          _ember['default'].$('.pac-container .pac-item:first').addClass('pac-item-selected').focus();
        }
      }
    }

  });
});