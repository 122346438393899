define('foogi-ember-client/mixins/wizard-navigation', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    prefixRoute: function prefixRoute(short_name) {
      return 'request.edit.wizard' + '.' + short_name;
    },
    truncateRoute: function truncateRoute(full_name) {
      return full_name.split('.').popObject();
    },

    // BEGIN ADJACENCY ROUTING MAPS
    wizardRoutes: ['index', 'who', 'availability', 'request.edit.time'],

    determineDestinationRoute: function determineDestinationRoute(curentRoute, destination) {
      if (destination === 'prev' || destination === 'next') {
        return this.determineAdjacentRoute(curentRoute, destination);
      }

      if (destination === 'index') {
        return destination;
      }

      var destinationRoute = this.prefixRoute(destination);
      return destinationRoute;
    },

    determineAdjacentRoute: function determineAdjacentRoute(currentRoute, direction) {
      if (!(direction === 'next' || direction === 'prev')) {
        _ember['default'].Logger.error("wizard/route#determineAdjacentRoute called direction: (can only be next/prev)", direction);
        return this.prefixRoute(currentRoute);
      }
      var indexModifier = direction === 'next' ? 1 : -1;
      var routeSet = this.wizardRoutes;

      var index = routeSet.indexOf(currentRoute);
      var destinationRoute = routeSet[index + indexModifier];

      if (destinationRoute.includes("request") || destinationRoute.includes("index")) {
        //TODO this is weird
        return destinationRoute;
      } else {
        return this.prefixRoute(destinationRoute);
      }
    },
    actions: {
      prev: function prev() {
        this.send('saveAndTransition', this.routeName, 'prev');
      },
      next: function next() {
        if (!this.controller.get('isValid')) {
          alert("This page isn't ready yet");
          return;
        }
        this.send('saveAndTransition', this.routeName, 'next');
      },
      close: function close() {
        this.send('justTransition', this.routeName, 'index');
      },

      justTransition: function justTransition(currentRoute, destination) {
        var destinationRoute = this.determineDestinationRoute(this.truncateRoute(currentRoute), destination);
        this.transitionTo(destinationRoute);
      },

      saveAndTransition: function saveAndTransition(currentRoute, destination) {
        // Validation and Saving
        var model = this.modelFor('request.edit.wizard');

        model.save().then(function () {
          model.reload();
        });

        // Transition Handling
        this.send('justTransition', currentRoute, destination);
      }

    }
  });
});