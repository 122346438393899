define('foogi-ember-client/initializers/custom-polyfill', ['exports'], function (exports) {
  exports['default'] = {
    name: 'custom-polyfill',
    initialize: function initialize() {

      // BEGIN String.includes()
      if (!String.prototype.includes) {
        String.prototype.includes = function () {
          'use strict';
          return String.prototype.indexOf.apply(this, arguments) !== -1;
        };
      }
      // END String.includes()
    }
  };
});