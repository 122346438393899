define("foogi-ember-client/pods/profile/edit/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "get-started", [], ["profile", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 12], [4, 17]]]]], [], []], "clearLocation", "clearLocation", "pressedEnterKey", "pressedEnterKey", "setMeetingLocation", "setMeetingLocation", "saveProfile", "next"], ["loc", [null, [3, 2], [9, 4]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 2
                },
                "end": {
                  "line": 22,
                  "column": 2
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("   ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "alert alert-info alert-wizard");
              dom.setAttribute(el1, "role", "alert");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("A ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("em");
              var el4 = dom.createTextNode("General meeting profile");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" is a set of defaults applied to any new meeting request. These can be changed and customised with each new meeting.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "steps");
              var el2 = dom.createTextNode("Step 1.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 14
                  },
                  "end": {
                    "line": 27,
                    "column": 169
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("30 mins");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 14
                  },
                  "end": {
                    "line": 30,
                    "column": 167
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("1 hour");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 14
                  },
                  "end": {
                    "line": 33,
                    "column": 169
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("2 hours");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 4
                },
                "end": {
                  "line": 36,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "help-text");
              var el2 = dom.createTextNode("How long do you like to schedule meetings for?");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "btn-group btn-group-justified");
              dom.setAttribute(el1, "role", "group");
              dom.setAttribute(el1, "aria-label", "...");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
              return morphs;
            },
            statements: [["block", "radio-button", [], ["value", 30, "groupValue", ["subexpr", "@mut", [["get", "model.meetingDuration", ["loc", [null, [27, 50], [27, 71]]]]], [], []], "changed", "updateDuration", "type", "radio", "radioClass", "btn btn-default"], 0, null, ["loc", [null, [27, 14], [27, 186]]]], ["block", "radio-button", [], ["value", 60, "groupValue", ["subexpr", "@mut", [["get", "model.meetingDuration", ["loc", [null, [30, 50], [30, 71]]]]], [], []], "changed", "updateDuration", "type", "radio", "radioClass", "btn btn-default"], 1, null, ["loc", [null, [30, 14], [30, 184]]]], ["block", "radio-button", [], ["value", 120, "groupValue", ["subexpr", "@mut", [["get", "model.meetingDuration", ["loc", [null, [33, 51], [33, 72]]]]], [], []], "changed", "updateDuration", "type", "radio", "radioClass", "btn btn-default"], 2, null, ["loc", [null, [33, 14], [33, 186]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 4
                },
                "end": {
                  "line": 40,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "steps");
              var el2 = dom.createTextNode("Step 2.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 4
                },
                "end": {
                  "line": 50,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "alert alert-info alert-wizard");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("The ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("strong");
              var el4 = dom.createTextNode("bookable window");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" is your weekly availablity and a bookable timeframe of how far into the future you are bookable. For each new meeting you schedule, Foogi will share this bookable window coupled with your live and up-to-date availability from your selected calendars.");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n     \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 10
                  },
                  "end": {
                    "line": 56,
                    "column": 230
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("Work Hours");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("9am – 6pm; Mon – Fri");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 59,
                    "column": 10
                  },
                  "end": {
                    "line": 59,
                    "column": 231
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("Waking Hours");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("6am – 11pm; Everyday");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 10
                  },
                  "end": {
                    "line": 62,
                    "column": 223
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("Everything");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("All day; Everyday");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 4
                },
                "end": {
                  "line": 65,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "help-text");
              var el2 = dom.createTextNode("What is your general availability during the week for which you want to allow bookings?");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "btn-group btn-group-justified");
              dom.setAttribute(el1, "role", "group");
              dom.setAttribute(el1, "aria-label", "...");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
              return morphs;
            },
            statements: [["block", "radio-button", [], ["value", "working", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityTimeMode", ["loc", [null, [56, 53], [56, 79]]]]], [], []], "changed", "updateTime", "type", "radio", "radioClass", "btn btn-default btn-radio"], 0, null, ["loc", [null, [56, 10], [56, 247]]]], ["block", "radio-button", [], ["value", "waking", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityTimeMode", ["loc", [null, [59, 52], [59, 78]]]]], [], []], "changed", "updateTime", "type", "radio", "radioClass", "btn btn-default btn-radio"], 1, null, ["loc", [null, [59, 10], [59, 248]]]], ["block", "radio-button", [], ["value", "all", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityTimeMode", ["loc", [null, [62, 49], [62, 75]]]]], [], []], "changed", "updateTime", "type", "radio", "radioClass", "btn btn-default btn-radio"], 2, null, ["loc", [null, [62, 10], [62, 240]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child5 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 10
                  },
                  "end": {
                    "line": 71,
                    "column": 222
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("A week");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("7 days from today");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 74,
                    "column": 10
                  },
                  "end": {
                    "line": 74,
                    "column": 225
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("A month");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("30 days from today");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 77,
                    "column": 10
                  },
                  "end": {
                    "line": 77,
                    "column": 226
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "title");
                var el2 = dom.createTextNode("Two months");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "info-text");
                var el2 = dom.createTextNode("60 days from today");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 67,
                  "column": 4
                },
                "end": {
                  "line": 80,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" How far from now would you like to meet ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "help-text");
              var el2 = dom.createTextNode("How far into the future do you generally like to schedule meetings?");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "btn-group btn-group-justified");
              dom.setAttribute(el1, "role", "group");
              dom.setAttribute(el1, "aria-label", "...");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "btn-group btn-radio");
              dom.setAttribute(el2, "role", "group");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("    \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [5]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
              return morphs;
            },
            statements: [["block", "radio-button", [], ["value", "week", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityRangeMode", ["loc", [null, [71, 50], [71, 77]]]]], [], []], "changed", "updateRange", "type", "radio", "radioClass", "btn btn-default btn-radio"], 0, null, ["loc", [null, [71, 10], [71, 239]]]], ["block", "radio-button", [], ["value", "month", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityRangeMode", ["loc", [null, [74, 51], [74, 78]]]]], [], []], "changed", "updateRange", "type", "radio", "radioClass", "btn btn-default btn-radio"], 1, null, ["loc", [null, [74, 10], [74, 242]]]], ["block", "radio-button", [], ["value", "any", "groupValue", ["subexpr", "@mut", [["get", "model.availabilityRangeMode", ["loc", [null, [77, 49], [77, 76]]]]], [], []], "changed", "updateRange", "type", "radio", "radioClass", "btn btn-default btn-radio"], 2, null, ["loc", [null, [77, 10], [77, 243]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 4
                },
                "end": {
                  "line": 84,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              dom.setAttribute(el1, "class", "steps");
              var el2 = dom.createTextNode("Step 3.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child7 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 4
                },
                "end": {
                  "line": 93,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "help-text");
              var el2 = dom.createTextNode("How can you be reached?");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "form-group wizard-input");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "for", "online-contact");
              var el3 = dom.createTextNode("For virtual meetings ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("em");
              var el4 = dom.createTextNode("(eg. Skype ID: princess.leia)");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" \n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "for", "phone-contact");
              var el3 = dom.createTextNode("For phone calls");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" \n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [3]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 3, 3);
              morphs[1] = dom.createMorphAt(element0, 7, 7);
              return morphs;
            },
            statements: [["inline", "input", [], ["type", "text", "class", "form-control", "id", "online-contact", "placeholder", "Skype ID: princess.leia", "value", ["subexpr", "@mut", [["get", "model.onlineContactDetails", ["loc", [null, [89, 117], [89, 143]]]]], [], []], "focus-out", "saveOnBlur"], ["loc", [null, [89, 12], [89, 168]]]], ["inline", "input", [], ["type", "text", "class", "form-control", "id", "phone-contact", "placeholder", "+1 415 555 5555", "value", ["subexpr", "@mut", [["get", "model.phoneContactDetails", ["loc", [null, [91, 108], [91, 133]]]]], [], []], "focus-out", "saveOnBlur"], ["loc", [null, [91, 12], [91, 158]]]]],
            locals: [],
            templates: []
          };
        })();
        var child8 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.6.2",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 104,
                    "column": 6
                  },
                  "end": {
                    "line": 119,
                    "column": 6
                  }
                },
                "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "help-text");
                var el2 = dom.createTextNode("What is your location?");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        \n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "form-group wizard-input");
                var el2 = dom.createTextNode(" \n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                dom.setAttribute(el2, "class", "help-text");
                var el3 = dom.createTextNode("Type the name of a city or town and then choose from the list");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 3, 3);
                return morphs;
              },
              statements: [["inline", "google-places-autocomplete", [], ["value", ["subexpr", "@mut", [["get", "model.locName", ["loc", [null, [110, 20], [110, 33]]]]], [], []], "pressedEnterKey", "pressedEnterKey", "placeChanged", "setMeetingLocation", "idName", "timeRequestInformation", "inputClass", "form-control", "clearLocation", "clearLocation", "placeholder", null, "size", "40"], ["loc", [null, [109, 10], [117, 25]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 95,
                  "column": 4
                },
                "end": {
                  "line": 120,
                  "column": 4
                }
              },
              "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "wizard-heading");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h3");
              var el3 = dom.createTextNode("Timezone");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "help-text");
              var el3 = dom.createTextNode("We work out your timezone based on your location");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "alert alert-warning alert-wizard");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createElement("strong");
              var el4 = dom.createTextNode("WARNING!");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Changing this will change the timezone for ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("strong");
              var el4 = dom.createTextNode("every pending");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" online meeting you've created!");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 7, 7, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "wizard-question", [], ["question-title", "Your city or town"], 0, null, ["loc", [null, [104, 6], [119, 26]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 0
              },
              "end": {
                "line": 121,
                "column": 0
              }
            },
            "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "wizard-heading");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createTextNode("General meeting profile");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "help-text");
            var el3 = dom.createTextNode("Each ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("strong");
            var el4 = dom.createTextNode("new request");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" will be prefilled with these settings");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "wizard-heading");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createTextNode("Bookable window");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "help-text");
            var el3 = dom.createTextNode("Each ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("strong");
            var el4 = dom.createTextNode("new request");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" will be prefilled with these settings");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(9);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 8, 8, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 12, 12, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 14, 14, contextualElement);
            morphs[5] = dom.createMorphAt(fragment, 16, 16, contextualElement);
            morphs[6] = dom.createMorphAt(fragment, 20, 20, contextualElement);
            morphs[7] = dom.createMorphAt(fragment, 21, 21, contextualElement);
            morphs[8] = dom.createMorphAt(fragment, 23, 23, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "firstRun", ["loc", [null, [16, 8], [16, 16]]]]], [], 0, null, ["loc", [null, [16, 2], [22, 9]]]], ["block", "wizard-question", [], ["question-title", "Meeting duration"], 1, null, ["loc", [null, [23, 4], [36, 24]]]], ["block", "if", [["get", "firstRun", ["loc", [null, [38, 10], [38, 18]]]]], [], 2, null, ["loc", [null, [38, 4], [40, 11]]]], ["block", "if", [["get", "firstRun", ["loc", [null, [45, 10], [45, 18]]]]], [], 3, null, ["loc", [null, [45, 4], [50, 11]]]], ["block", "wizard-question", [], ["question-title", "Allow bookings during"], 4, null, ["loc", [null, [52, 4], [65, 24]]]], ["block", "wizard-question", [], ["question-title", "Allow bookings for"], 5, null, ["loc", [null, [67, 4], [80, 24]]]], ["block", "if", [["get", "firstRun", ["loc", [null, [82, 10], [82, 18]]]]], [], 6, null, ["loc", [null, [82, 4], [84, 11]]]], ["block", "wizard-question", [], ["question-title", "General contact details"], 7, null, ["loc", [null, [85, 4], [93, 24]]]], ["block", "unless", [["get", "firstRun", ["loc", [null, [95, 14], [95, 22]]]]], [], 8, null, ["loc", [null, [95, 4], [120, 15]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 122,
              "column": 0
            }
          },
          "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "wizard-panel", [], ["close", "close", "prev", "prev", "labelPrev", "Cancel", "next", "next", "labelNext", ["subexpr", "@mut", [["get", "saveButtonText", ["loc", [null, [11, 83], [11, 97]]]]], [], []], "isValid", ["subexpr", "@mut", [["get", "isValid", ["loc", [null, [11, 106], [11, 113]]]]], [], []]], 0, null, ["loc", [null, [11, 0], [121, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 122,
            "column": 7
          }
        },
        "moduleName": "foogi-ember-client/pods/profile/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "firstRun", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, 1, ["loc", [null, [1, 0], [122, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});