define('foogi-ember-client/mixins/utility-functions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    formatTimeInTz: function formatTimeInTz(timeProp, tzProp, formatString) {
      var time = this.get(timeProp);
      if (time.isValid()) {
        var tz = this.get(tzProp);
        var timeInTz = moment(time).tz(tz);
        return timeInTz.format(formatString);
      } else {
        return '—';
      }
    }
  });
});