define('foogi-ember-client/pods/request/edit/wizard/what/route', ['exports', 'ember', 'foogi-ember-client/mixins/wizard-navigation'], function (exports, _ember, _foogiEmberClientMixinsWizardNavigation) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsWizardNavigation['default'], {
    actions: {
      updateOnline: function updateOnline(value) {
        var req = this.modelFor(this.routeName);
        req.set('meetingOnline', value);
        req.save();
      }
    }
  });
});