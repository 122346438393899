define('foogi-ember-client/pods/profile/edit/route', ['exports', 'ember', 'foogi-ember-client/mixins/google-maps-actions'], function (exports, _ember, _foogiEmberClientMixinsGoogleMapsActions) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend(_foogiEmberClientMixinsGoogleMapsActions['default'], {
    currentUser: inject.service('session-user'),
    actions: {
      focusEmail: function focusEmail() {
        $('.form-control#email').focus();
      },
      focusLocation: function focusLocation() {
        $('.google-places-autocomplete .form-control').focus();
      },

      updateDuration: function updateDuration(value) {
        var profile = this.modelFor(this.routeName);
        profile.set('meetingDuration', value);
      },
      updateTime: function updateTime(value) {
        var profile = this.modelFor(this.routeName);
        profile.set('availabilityTimeMode', value);
      },
      updateRange: function updateRange(value) {
        var profile = this.modelFor(this.routeName);
        profile.set('availabilityRangeMode', value);
      },

      prev: function prev() {
        this.send('backOnePage');
      },

      next: function next() {
        var _this = this;

        var profilePreviouslyCompleted = this.get('currentUser.me.defaultProfileCompleted');
        var profile = this.modelFor(this.routeName);
        profile.save().then(function () {
          _this.get('currentUser').reload();
        });

        if (profilePreviouslyCompleted) {
          this.send('backOnePage');
        } else {
          this.transitionTo('index');
        }
      }
    }
  });
});