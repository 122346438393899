define("foogi-ember-client/pods/login/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/login/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-lg btn-social btn-google");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2, "width", "42px");
          dom.setAttribute(el2, "height", "42px");
          dom.setAttribute(el2, "viewBox", "0 0 42 42");
          dom.setAttribute(el2, "version", "1.1");
          dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
          dom.setAttribute(el2, "xmlns:xlink", "http://www.w3.org/1999/xlink");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" Generator: Sketch 3.7.1 (28215) - http://www.bohemiancoding.com/sketch ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("title");
          var el4 = dom.createTextNode("Google Logo 42px");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("desc");
          var el4 = dom.createTextNode("Created with Sketch.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("defs");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("g");
          dom.setAttribute(el3, "id", "Page-1");
          dom.setAttribute(el3, "stroke", "none");
          dom.setAttribute(el3, "stroke-width", "1");
          dom.setAttribute(el3, "fill", "none");
          dom.setAttribute(el3, "fill-rule", "evenodd");
          var el4 = dom.createTextNode("\n              ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("g");
          dom.setAttribute(el4, "id", "Google-Logo-42px");
          var el5 = dom.createTextNode("\n                  ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("g");
          dom.setAttribute(el5, "id", "Group");
          dom.setAttribute(el5, "transform", "translate(12.000000, 12.000000)");
          var el6 = dom.createTextNode("\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("path");
          dom.setAttribute(el6, "d", "M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z");
          dom.setAttribute(el6, "id", "Shape");
          dom.setAttribute(el6, "fill", "#4285F4");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("path");
          dom.setAttribute(el6, "d", "M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z");
          dom.setAttribute(el6, "id", "Shape");
          dom.setAttribute(el6, "fill", "#34A853");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("path");
          dom.setAttribute(el6, "d", "M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z");
          dom.setAttribute(el6, "id", "Shape");
          dom.setAttribute(el6, "fill", "#FBBC05");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                      ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("path");
          dom.setAttribute(el6, "d", "M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z");
          dom.setAttribute(el6, "id", "Shape");
          dom.setAttribute(el6, "fill", "#EA4335");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                  ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      Sign in with Google\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["element", "action", ["customAuthlogin", "google"], [], ["loc", [null, [13, 53], [13, 90]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 4
            },
            "end": {
              "line": 48,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/login/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-lg btn-social btn-office");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2, "width", "32px");
          dom.setAttribute(el2, "height", "32px");
          dom.setAttribute(el2, "viewBox", "0 0 32 32");
          dom.setAttribute(el2, "version", "1.1");
          dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
          dom.setAttribute(el2, "xmlns:xlink", "http://www.w3.org/1999/xlink");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" Generator: Sketch 3.7.1 (28215) - http://www.bohemiancoding.com/sketch ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("title");
          var el4 = dom.createTextNode("Microsoft Logo");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("desc");
          var el4 = dom.createTextNode("Created with Sketch.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("defs");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("g");
          dom.setAttribute(el3, "id", "Page-1");
          dom.setAttribute(el3, "stroke", "none");
          dom.setAttribute(el3, "stroke-width", "1");
          dom.setAttribute(el3, "fill", "none");
          dom.setAttribute(el3, "fill-rule", "evenodd");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("g");
          dom.setAttribute(el4, "id", "Microsoft-Logo");
          dom.setAttribute(el4, "fill", "#FFFFFF");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("path");
          dom.setAttribute(el5, "d", "M11.3202625,11.3412289 L11.3202625,20.1556935 C10.2235555,20.5843731 9.1307558,21.0112502 8.03840261,21.4376766 C8.00480033,21.3808948 8.00044654,21.3478848 8.00044654,21.3144241 C8,17.7528342 8,14.1907937 8,10.6379915 C8.04889634,10.5777172 8.11208202,10.5701688 8.16700668,10.5500023 C11.2893395,9.38068138 14.4125654,8.21259977 17.5352331,7.04339154 C17.6434078,7.00283315 17.7415354,6.9830046 17.8614319,7.01826787 C19.5748133,7.51555879 21.2898692,8.00710393 23.0044786,8.50045167 C23.0293733,8.50800004 23.054268,8.51678769 23.0895448,8.52816657 L23.0895448,23.5399531 C22.5972323,23.6768376 22.1092737,23.8137222 21.6208684,23.9483535 C20.3775841,24.2911846 19.1335182,24.6314244 17.891127,24.9768467 C17.7694443,25.010758 17.6630557,25.0081668 17.5434941,24.9641158 C14.4722903,23.8291569 11.3996353,22.6976905 8.32798505,21.5657735 C8.26736698,21.5433537 8.2079769,21.51823 8.15695949,21.4641522 C11.3342169,21.9002675 14.5114744,22.3359322 17.6882853,22.7720476 C17.7157476,22.5991111 17.7227807,10.0949597 17.696658,9.82209185 C15.5720115,10.3281704 13.4504908,10.8337983 11.3202625,11.3412289 Z");
          dom.setAttribute(el5, "id", "path14-copy");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        Sign in with Office 365\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element1);
          return morphs;
        },
        statements: [["element", "action", ["customAuthlogin", "outlook"], [], ["loc", [null, [34, 53], [34, 91]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 49,
              "column": 4
            },
            "end": {
              "line": 64,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/login/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-lg btn-social btn-office");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.setNamespace("http://www.w3.org/2000/svg");
          var el2 = dom.createElement("svg");
          dom.setAttribute(el2, "width", "32px");
          dom.setAttribute(el2, "height", "32px");
          dom.setAttribute(el2, "viewBox", "0 0 32 32");
          dom.setAttribute(el2, "version", "1.1");
          dom.setAttribute(el2, "xmlns", "http://www.w3.org/2000/svg");
          dom.setAttribute(el2, "xmlns:xlink", "http://www.w3.org/1999/xlink");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment(" Generator: Sketch 3.7.1 (28215) - http://www.bohemiancoding.com/sketch ");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("title");
          var el4 = dom.createTextNode("Microsoft Logo");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("desc");
          var el4 = dom.createTextNode("Created with Sketch.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("defs");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("g");
          dom.setAttribute(el3, "id", "Page-1");
          dom.setAttribute(el3, "stroke", "none");
          dom.setAttribute(el3, "stroke-width", "1");
          dom.setAttribute(el3, "fill", "none");
          dom.setAttribute(el3, "fill-rule", "evenodd");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("g");
          dom.setAttribute(el4, "id", "Microsoft-Logo");
          dom.setAttribute(el4, "fill", "#FFFFFF");
          var el5 = dom.createTextNode("\n                    ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("path");
          dom.setAttribute(el5, "d", "M11.3202625,11.3412289 L11.3202625,20.1556935 C10.2235555,20.5843731 9.1307558,21.0112502 8.03840261,21.4376766 C8.00480033,21.3808948 8.00044654,21.3478848 8.00044654,21.3144241 C8,17.7528342 8,14.1907937 8,10.6379915 C8.04889634,10.5777172 8.11208202,10.5701688 8.16700668,10.5500023 C11.2893395,9.38068138 14.4125654,8.21259977 17.5352331,7.04339154 C17.6434078,7.00283315 17.7415354,6.9830046 17.8614319,7.01826787 C19.5748133,7.51555879 21.2898692,8.00710393 23.0044786,8.50045167 C23.0293733,8.50800004 23.054268,8.51678769 23.0895448,8.52816657 L23.0895448,23.5399531 C22.5972323,23.6768376 22.1092737,23.8137222 21.6208684,23.9483535 C20.3775841,24.2911846 19.1335182,24.6314244 17.891127,24.9768467 C17.7694443,25.010758 17.6630557,25.0081668 17.5434941,24.9641158 C14.4722903,23.8291569 11.3996353,22.6976905 8.32798505,21.5657735 C8.26736698,21.5433537 8.2079769,21.51823 8.15695949,21.4641522 C11.3342169,21.9002675 14.5114744,22.3359322 17.6882853,22.7720476 C17.7157476,22.5991111 17.7227807,10.0949597 17.696658,9.82209185 C15.5720115,10.3281704 13.4504908,10.8337983 11.3202625,11.3412289 Z");
          dom.setAttribute(el5, "id", "path14-copy");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        Sign in with MS Graph (experimental)\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["customAuthlogin", "ms-graph"], [], ["loc", [null, [50, 53], [50, 92]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 33
            },
            "end": {
              "line": 69,
              "column": 83
            }
          },
          "moduleName": "foogi-ember-client/pods/login/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Click here");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 71,
            "column": 6
          }
        },
        "moduleName": "foogi-ember-client/pods/login/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h1");
        dom.setAttribute(el3, "class", "text-capitalize");
        var el4 = dom.createTextNode("\n      Secure more meetings");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("br");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Close more deals\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("Just one email to schedule your one-on-one meetings");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createTextNode("Use a different calendar? ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element3, 1, 1);
        morphs[1] = dom.createMorphAt(element3, 2, 2);
        morphs[2] = dom.createMorphAt(element3, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4, 1, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "env.featureFlags.authGoogle", ["loc", [null, [12, 10], [12, 37]]]]], [], 0, null, ["loc", [null, [12, 4], [32, 11]]]], ["block", "if", [["get", "env.featureFlags.authOutlook", ["loc", [null, [33, 10], [33, 38]]]]], [], 1, null, ["loc", [null, [33, 4], [48, 11]]]], ["block", "if", [["get", "env.featureFlags.authGraph", ["loc", [null, [49, 10], [49, 36]]]]], [], 2, null, ["loc", [null, [49, 4], [64, 11]]]], ["block", "link-to", ["login.register-interest"], [], 3, null, ["loc", [null, [69, 33], [69, 83]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});