define('foogi-ember-client/services/session-user', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  var Service = _ember['default'].Service;
  var inject = _ember['default'].inject;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;
  exports['default'] = Service.extend({
    session: inject.service('session'),
    store: inject.service(),

    userId: computed.alias('session.data.authenticated.user_id'),
    me: computed('userId', function () {
      var userId = this.get('userId');
      if (!isEmpty(userId)) {
        return _emberData['default'].PromiseObject.create({
          promise: this.get('store').find('user', userId)
        });
      }
    }),

    reload: function reload() {
      var userId = this.get('userId');
      this.get('store').findRecord('user', userId, { reload: true });
    }
  });
});