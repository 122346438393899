define("foogi-ember-client/pods/request/edit/time/display/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 3
              },
              "end": {
                "line": 16,
                "column": 75
              }
            },
            "moduleName": "foogi-ember-client/pods/request/edit/time/display/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "calendar.calItem.summary", ["loc", [null, [16, 47], [16, 75]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 5
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/request/edit/time/display/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("			");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "paper-checkbox", [], ["checked", ["subexpr", "@mut", [["get", "calendar.display", ["loc", [null, [16, 29], [16, 45]]]]], [], []]], 0, null, ["loc", [null, [16, 3], [16, 94]]]]],
        locals: ["calendar"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/pods/request/edit/time/display/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "white-text");
        var el3 = dom.createTextNode("Calendars");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "cal-list");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el2 = dom.createElement("div");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [4]), 1, 1);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createAttrMorph(element2, 'class');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", ":sidebar", [["get", "sideBarOpen:sidebar-open", ["loc", [null, [1, 23], [1, 47]]]], ["get", "mobile:sidebar-mobile", ["loc", [null, [1, 48], [1, 69]]]]], [], ["loc", [null, [1, 12], [1, 71]]]]]]], ["block", "each", [["get", "calendars", ["loc", [null, [15, 13], [15, 22]]]]], [], 0, null, ["loc", [null, [15, 5], [17, 11]]]], ["element", "action", ["toggleSidebar"], [], ["loc", [null, [20, 3], [20, 29]]]], ["attribute", "class", ["concat", [["subexpr", ":back-button", [["get", "sideBarOpen:back-button-open", ["loc", [null, [20, 57], [20, 85]]]], ["get", "mobile:toggle-mobile", ["loc", [null, [20, 86], [20, 106]]]]], [], ["loc", [null, [20, 42], [20, 108]]]]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});