define('foogi-ember-client/pods/request/edit/wizard/what/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    pageTitle: 'What is the meeting about?',

    isValid: _ember['default'].computed.notEmpty('model.meetingSummary'),

    meetingTypeImage: _ember['default'].computed('model.meetingOnline', function () {
      var online = this.get('model.meetingOnline');
      var img = '';
      if (online) {
        img = 'how-icon-online.png';
      } else {
        img = 'how-icon-place.png';
      }
      var baseUrl = 'assets/images/request/wizard/';
      return this.assets.resolve(baseUrl + img);
    })
  });
});