define('foogi-ember-client/pods/index/controller', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  var filterBy = computed.filterBy;
  var alias = computed.alias;
  var not = computed.not;
  exports['default'] = Controller.extend({
    currentUser: inject.service('session-user'),
    pageTitle: "Meeting requests",
    responded: filterBy('model', 'statusResponded', true),
    others: filterBy('model', 'statusResponded', false),
    cards: alias('model.length'),
    firstTime: not('currentUser.me.defaultProfileCompleted'),
    defaultProfile: null
  });
});