define("foogi-ember-client/pods/components/google-places-autocomplete/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": false,
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 6
          }
        },
        "moduleName": "foogi-ember-client/pods/components/google-places-autocomplete/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "clear-input");
        dom.setAttribute(el2, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-times-circle");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'id');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createElementMorph(element1);
        return morphs;
      },
      statements: [["attribute", "id", ["get", "idName", ["loc", [null, [1, 10], [1, 16]]]]], ["inline", "input", [], ["label", ["subexpr", "@mut", [["get", "label", ["loc", [null, [2, 16], [2, 21]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [2, 28], [2, 33]]]]], [], []], "idName", ["subexpr", "@mut", [["get", "idName", ["loc", [null, [2, 41], [2, 47]]]]], [], []], "size", ["subexpr", "@mut", [["get", "size", ["loc", [null, [2, 53], [2, 57]]]]], [], []], "placeholder", ["subexpr", "@mut", [["get", "placeholder", ["loc", [null, [2, 70], [2, 81]]]]], [], []], "class", ["subexpr", "@mut", [["get", "inputClass", ["loc", [null, [2, 88], [2, 98]]]]], [], []], "tabindex", ["subexpr", "@mut", [["get", "tabindex", ["loc", [null, [2, 108], [2, 116]]]]], [], []]], ["loc", [null, [2, 2], [2, 118]]]], ["element", "action", ["clearLocation"], [], ["loc", [null, [3, 28], [3, 54]]]]],
      locals: [],
      templates: []
    };
  })());
});