define('foogi-ember-client/pods/hash/transform', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      var camelizedData = JSON.stringify(serialized).camelize();
      return _ember['default'].Object.create(JSON.parse(camelizedData));
    },

    serialize: function serialize(deserialized) {
      if (deserialized) {
        var decamelizedData = JSON.stringify(deserialized).decamelize();
        return JSON.parse(decamelizedData);
      }
    }
  });
});