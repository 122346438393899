define("foogi-ember-client/utils/computed/search", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = search;

  var computed = _ember["default"].computed;

  function search(dependentKey, propertyKey, searchQueryKey, returnEmptyArray) {
    returnEmptyArray = typeof returnEmptyArray === "undefined" ? false : returnEmptyArray;
    return computed("" + dependentKey + ".@each." + propertyKey, searchQueryKey, function () {
      var items, query;
      if (returnEmptyArray && !this.get(searchQueryKey)) {
        return _ember["default"].A([]);
      }

      query = this.get(searchQueryKey) || '';
      query = query.toLowerCase();
      items = this.get(dependentKey) || _ember["default"].A([]);

      return items.filter(function (item) {
        if (item.get(propertyKey)) {
          return item.get(propertyKey).toLowerCase().indexOf(query) !== -1;
        }
      });
    });
  }
});