define('foogi-ember-client/initializers/inject-env', ['exports', 'foogi-ember-client/config/environment'], function (exports, _foogiEmberClientConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register('env:main', _foogiEmberClientConfigEnvironment['default'], { singleton: true, instantiate: false });
    application.inject('component', 'env', 'env:main');
    application.inject('controller', 'env', 'env:main');
  }

  exports['default'] = {
    name: 'inject-env',
    initialize: initialize
  };
});