define('foogi-ember-client/pods/calendar/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = Model.extend({
    account: belongsTo('account'),
    calendarEvents: hasMany('calendarEvent'),
    name: attr('string'),
    summary: attr('string'),
    enabled: attr('boolean'),
    visible: alias('enabled'),
    backgroundColor: attr('string'),
    forgroundColor: attr('string'),
    token: attr('string')
  });
});