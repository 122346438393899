define('foogi-ember-client/pods/profile/model', ['exports', 'ember-data', 'foogi-ember-client/pods/array/transform'], function (exports, _emberData, _foogiEmberClientPodsArrayTransform) {
  // jshint ignore:line

  exports['default'] = _emberData['default'].Model.extend({
    //Attrs
    meetingDuration: _emberData['default'].attr('number'),
    meetingOnline: _emberData['default'].attr('boolean'),
    meetingTimezone: _emberData['default'].attr('string'),
    locPlaceId: _emberData['default'].attr('string'),
    locName: _emberData['default'].attr('string'),
    locAddress: _emberData['default'].attr('string'),
    locLat: _emberData['default'].attr('string'),
    locLng: _emberData['default'].attr('string'),
    locRegion: _emberData['default'].attr('string'),
    availabilityRangeMode: _emberData['default'].attr('string'),
    availabilityTimeMode: _emberData['default'].attr('string'),
    selectedCalendars: _emberData['default'].attr('array'),
    phoneContactDetails: _emberData['default'].attr('string'),
    onlineContactDetails: _emberData['default'].attr('string')

  });
});
// Methods