define('foogi-ember-client/pods/request/edit/time/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-user-session'], function (exports, _ember, _foogiEmberClientMixinsRequestUserSession) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestUserSession['default'], {
    model: function model(params) {
      // jshint ignore:line
      var req = this.modelFor('request');
      var token = req.get('id');

      return _ember['default'].RSVP.hash({
        request: this.store.findRecord('timeRequest', token),
        days: this.store.query('day', { token: token }),
        metaCalendar: this.store.findRecord('metaCalendar', token),
        accounts: this.store.findAll('account'),
        calendars: this.store.query('calendar', { token: token })
      });
    },

    afterModel: function afterModel(model, transition) {
      // jshint ignore:line
      var validRecipient = model.request.get('validRecipient');
      if (!validRecipient) {
        this.transitionTo('request.edit.invitee');
      }

      var req = model.request;
      var token = req.id;
      var meetingOnline = req.get('meetingOnline');
      var tzTarget = model.metaCalendar.get('tzTarget');
      var tzReceiver = model.request.get('timezoneReceiver');

      // If we are are the sender:
      if (this.userIsOwner(req)) {

        // and the meeting is in-person and the timezones are out of sync (metacalendar is using old data)
        if (!meetingOnline && tzTarget !== tzReceiver) {
          location.reload();
        }

        // If we are are the receiver:
      } else {
          // First, allow the receiver to correct their timezone
          if (meetingOnline) {
            this.checkTimezoneAndTransition(token, tzTarget);
          }

          // If the request has been updated but the metacalendar is still using old data then reload.
          if (tzTarget !== tzReceiver) {
            location.reload();
            // model.metaCalendar.reload();
          }
        }

      this.applyDays(model.days, model.metaCalendar);
      this.loadSelectedCalendars(token, model.calendars);
    },

    applyDays: function applyDays(days, metaCalendar) {
      metaCalendar.set('days', days);
    },

    loadSelectedCalendars: function loadSelectedCalendars(token, calendars) {
      var _this = this;

      calendars.forEach(function (cal) {
        if (cal.get('enabled') === true) {
          _this.store.query('calendarEvent', {
            calendar_id: cal.id,
            token: token
          });
        }
      });
    },

    checkTimezoneAndTransition: function checkTimezoneAndTransition(token, tzTarget) {
      // Get saved data from sessionStorage
      var reqPreferences = JSON.parse(sessionStorage.getItem(token));

      var overrideTimezone = false;
      if (reqPreferences) {
        overrideTimezone = reqPreferences.overrideTimezone;
      }

      if (!overrideTimezone) {
        var tzDetected = jstz.determine().name();
        if (tzDetected !== tzTarget) {
          this.transitionTo('request.edit.detected-timezone');
        }
      }
    },

    actions: {
      setMeetingStart: function setMeetingStart(time) {
        _ember['default'].Logger.debug("Setting Meeting time: " + time);
        var req = this.modelFor('request');

        req.set('meetingTime', null); // Property doesn't automatically refresh because we're recycling the moment object
        req.set('meetingTime', moment(time));
      },
      validate: function validate() {
        var _this2 = this;

        //action confirm:
        var req = this.modelFor('request');
        req.save().then(function () {
          _this2.transitionTo('request.confirm', req.id);
        });
      },
      back: function back() {
        //action back:
        this.transitionTo('request.confirm', this.modelFor('request').id);
      },
      toggleCalendar: function toggleCalendar(calendar, enabledValue) {
        calendar.set('enabled', enabledValue);
        calendar.save();
        if (enabledValue === true) {
          this.store.query('calendarEvent', {
            calendar_id: calendar.get('id'),
            token: calendar.get('token')
          });
        }
      },
      updateDuration: function updateDuration(value) {
        var req = this.modelFor('request');
        req.set('meetingDuration', value);
      },
      authenticate: function authenticate(provider) {
        this.send('customAuthlogin', provider);
      }
    }

  });
});
/* global jstz */