define("foogi-ember-client/templates/components/loading-balls", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/templates/components/loading-balls.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "class", "icon-loading");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(-8 0)");
        dom.setAttribute(el2, "d", "M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12");
        var el3 = dom.createTextNode(" \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animateTransform");
        dom.setAttribute(el3, "attributeName", "transform");
        dom.setAttribute(el3, "type", "translate");
        dom.setAttribute(el3, "values", "-8 0; 2 0; 2 0;");
        dom.setAttribute(el3, "dur", "0.8s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keytimes", "0;.25;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(2 0)");
        dom.setAttribute(el2, "d", "M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12");
        var el3 = dom.createTextNode(" \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animateTransform");
        dom.setAttribute(el3, "attributeName", "transform");
        dom.setAttribute(el3, "type", "translate");
        dom.setAttribute(el3, "values", "2 0; 12 0; 12 0;");
        dom.setAttribute(el3, "dur", "0.8s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keytimes", "0;.35;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(12 0)");
        dom.setAttribute(el2, "d", "M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12");
        var el3 = dom.createTextNode(" \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animateTransform");
        dom.setAttribute(el3, "attributeName", "transform");
        dom.setAttribute(el3, "type", "translate");
        dom.setAttribute(el3, "values", "12 0; 22 0; 22 0;");
        dom.setAttribute(el3, "dur", "0.8s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keytimes", "0;.45;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(24 0)");
        dom.setAttribute(el2, "d", "M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12");
        var el3 = dom.createTextNode(" \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animateTransform");
        dom.setAttribute(el3, "attributeName", "transform");
        dom.setAttribute(el3, "type", "translate");
        dom.setAttribute(el3, "values", "22 0; 32 0; 32 0;");
        dom.setAttribute(el3, "dur", "0.8s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keytimes", "0;.55;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "loadingSvgSize", ["loc", [null, [1, 89], [1, 103]]]]], ["attribute", "height", ["get", "loadingSvgSize", ["loc", [null, [1, 115], [1, 129]]]]], ["attribute", "fill", ["get", "loadingSvgColor", ["loc", [null, [1, 139], [1, 154]]]]]],
      locals: [],
      templates: []
    };
  })());
});