define('foogi-ember-client/pods/components/google-places-autocomplete/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    type: 'text',
    classNames: ['google-places-autocomplete'],
    label: null,
    place: null,
    idName: null,
    types: [],
    size: null,
    placeholder: null,
    componentRestrictions: {},
    placeObserver: _ember['default'].observer('place', function () {
      this.sendAction('placeChanged', this.get('place'));
    }),
    didInsertElement: function didInsertElement() {
      var self = this;
      var options = {
        types: this.get('types'),
        componentRestrictions: this.get('componentRestrictions')
      };
      var inputToAttachTo = this.$('div#' + this.get('idName') + ' input')[0];
      var autocomplete = new google.maps.places.Autocomplete(inputToAttachTo, options);
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace();
        if (place) {
          self.set('place', place);
        } else {
          _ember['default'].Logger.error('No google place was returned');
        }
        _ember['default'].Logger.debug(self.get('place'));
      });
    },

    keyPress: function keyPress(e) {
      var selector = '#' + this.get('inputToAttachTo') + '.md-input-focused';
      if (_ember['default'].$(selector) && e.keyCode === 13) {
        this.sendAction('pressedEnterKey');
      }
    },

    actions: {
      placeChanged: function placeChanged() {
        this.sendAction('placeChanged', this.get('place'));
      },
      clearLocation: function clearLocation() {
        this.sendAction('clearLocation');
      }
    }
  });
});
/* global google */