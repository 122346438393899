define('foogi-ember-client/pods/request/edit/detected-timezone/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-edit-return-to'], function (exports, _ember, _foogiEmberClientMixinsRequestEditReturnTo) {
	exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestEditReturnTo['default'], {
		actions: {
			save: function save() {
				var _this = this;

				var req = this.modelFor('request');

				req.save().then(function () {
					// Save data to sessionStorage
					var reqPreferences = { overrideTimezone: true };
					sessionStorage.setItem(req.id, JSON.stringify(reqPreferences));

					_this.transitionTo('request.edit.time', req.id);
				});
			}
		}
	});
});