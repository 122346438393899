define('foogi-ember-client/pods/application/controller', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var isPresent = _ember['default'].isPresent;
  var $ = _ember['default'].$;
  var inject = _ember['default'].inject;
  exports['default'] = Controller.extend({
    classNames: ['application-container'],
    calendars: null,

    session: inject.service('session'),
    currentUser: inject.service('session-user'),

    foogsterFlag: computed('currentUser.me', function () {
      var email = this.get('currentUser.me.primaryEmail');
      return isPresent(email) && email.endsWith('@foogi.me');
    })

  });
});