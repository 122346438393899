define('foogi-ember-client/pods/request/confirm/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-user-session', 'foogi-ember-client/config/environment'], function (exports, _ember, _foogiEmberClientMixinsRequestUserSession, _foogiEmberClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestUserSession['default'], {
    ajax: _ember['default'].inject.service(),

    renderTemplate: function renderTemplate(controller, model) {
      if (this.userIsOwner(model)) {
        this.render('request.confirm.sender');
      } else {
        this.render('request.confirm.receiver');
      }
    },

    afterModel: function afterModel(model) {
      var validTime = model.get('validTime');
      if (!validTime) {
        this.transitionTo('request.edit.time');
      }
    },

    actions: {
      toggleMap: function toggleMap() {
        this.controllerFor(this.routeName).toggleProperty('showMap');
      },
      toggleTime: function toggleTime() {
        this.controllerFor(this.routeName).toggleProperty('showTime');
      },
      toggleContacts: function toggleContacts() {
        this.controllerFor(this.routeName).toggleProperty('showContacts');
        //this.controllerFor(this.routeName).set('invalidOnlineContacts', false); //TODO remove when the online contacts procs properly
      },
      jumpTo: function jumpTo(cardTarget) {
        this.transitionTo('request.edit.' + cardTarget);
      },

      save: function save() {
        this.modelFor(this.routeName).save();
      },

      updateDuration: function updateDuration(value) {
        var req = this.modelFor(this.routeName);
        req.set('meetingDuration', value);
        req.save();
      },

      resetSummary: function resetSummary() {
        var req = this.modelFor(this.routeName);
        req.set('meetingSummaryCustomised', false);
        req.save();
      },

      tabClick: function tabClick(value) {
        this.set('controller.timeTabDuration', value);
      },

      setLoading: function setLoading() {
        this.set('controller.isLoading', true);
      },
      setNotLoading: function setNotLoading() {
        this.set('controller.isLoading', false);
      },

      saveAndSend: function saveAndSend(endpoint, destination) {
        var _this = this;

        if (this.get('controller.isLoading')) {
          return;
        }
        var req = this.modelFor('request');
        var url = _foogiEmberClientConfigEnvironment['default'].API.HOST + '/' + _foogiEmberClientConfigEnvironment['default'].API_NAMESPACE + '/time_requests/' + req.get('id') + '/' + endpoint;
        this.get('controller').set('isLoading', true);
        req.save().then(function () {
          if (endpoint === 'accept_changes') {
            // hack to handle unauthorised request, this can be much cleaner
            _this.send('acceptRequest', url, destination);
          } else {
            _this.send('sendRequest', url, destination);
          }
        }, function () {
          _this.send('setNotLoading');
          alert('could not save changes. email support?');
        });
      },

      sendRequest: function sendRequest(url, destination) {
        var _this2 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
          _this2.get('ajax').request(url, {
            method: "POST",
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader(headerName, headerValue);
            }
          }).then(function () {
            _this2.send('setNotLoading');
            _this2.transitionTo(destination);
            flashMessages.success("Request sent successfully");
            _this2.segment.trackEvent('Confirmation (Olli): Event - Request successfully sent');
          }, function () {
            _this2.send('setNotLoading');
            alert('Failed to send... Please contact support!');
            _this2.segment.trackEvent('Confirmation (Olli): Error - Failed to send');
          });
        });
      },

      acceptRequest: function acceptRequest(url, destination) {
        var _this3 = this;

        var flashMessages = _ember['default'].get(this, 'flashMessages');
        // this.get('session').authorize('authorizer:oauth2', (headerName, headerValue) => { // Don't authorize Indi's requests because indi might not be signed in
        this.get('ajax').request(url, {
          method: "POST"
        }). // beforeSend: function(xhr) {
        //    xhr.setRequestHeader(headerName, headerValue);
        // }
        then(function () {
          _this3.send('setNotLoading');
          _this3.transitionTo(destination);
          flashMessages.success("Request confirmed");
          _this3.segment.trackEvent('Confirmation (Indi): Event - Request accepted');
        }, function () {
          _this3.send('setNotLoading');
          alert('Failed to accept changes... Please contact support!');
          _this3.segment.trackEvent('Confirmation (Indi): Error - Failed to accept');
        });
        // });
      },

      //  SENDER ACTIONS
      backToDash: function backToDash() {
        var _this4 = this;

        this.segment.trackEvent('Confirmation (Olli): Button - Back to dash');
        // Save current request settings
        var flashMessages = _ember['default'].get(this, 'flashMessages');
        var model = this.modelFor('request');
        // Transition to dashboard
        this.transitionTo('index');
        flashMessages.info('Saving request...');
        model.save().then(function () {
          flashMessages.success('Request saved as draft');
        }, function () {
          flashMessages.danger('Something went wrong! Press back and try again?');
          _this4.transitionTo('request.confirm', model);
        });
      },
      prev: function prev() {
        var model = this.modelFor('request');
        this.transitionTo('request.edit.time', model);
      },

      doSend: function doSend() {
        this.segment.trackEvent('Confirmation (Olli): Button - Send request');
        if (this.controller.get('senderPreFlight')) {
          this.send('saveAndSend', 'send_request', 'index');
        } else {
          alert('Please compose a message in the red text box before sending.');
          this.segment.trackEvent('Confirmation (Olli): Warning - Send request failed (incomplete)');
        }
      },

      doActivate: function doActivate() {
        var req = this.modelFor(this.routeName);
        var requestUrl = _foogiEmberClientConfigEnvironment['default'].hostUrl + '/#/request/' + req.get('token');
        req.set('meetingOutboundMessage', 'Hi ' + req.get('receiverName') + '\nWould love to connect with you. Want to schedule at ' + requestUrl + ' so we can avoid the back and forth of finding a time?');
        req.save();
        this.segment.trackEvent('Confirmation (Olli): Button - Activate request');
        if (this.controller.get('senderPreFlight')) {
          this.send('saveAndSend', 'activate_request', 'request.outbound');
        } else {
          alert('Please compose a message in the red text box before sending. This message will end up in the event description so dont just mash the keyboard');
          this.segment.trackEvent('Confirmation (Olli): Warning - Activating request failed (incomplete)');
        }
      },

      addMeetingNote: function addMeetingNote() {
        this.controllerFor(this.routeName).toggleProperty('showMeetingNote');
      },

      // If the message is blank, this will fill the message with the placeholder text
      // and select the [write your message here] line so you can just type over it
      prefillMessage: function prefillMessage() {
        var req = this.modelFor(this.routeName);
        if (_ember['default'].isPresent(req.get('meetingOutboundMessage'))) {
          return;
        }

        var firstLine = "Dear " + req.get('receiverDisplayName') + ',\n';
        var temporaryText = '[write your message here]';

        var text = firstLine + temporaryText + '\n-' + req.get('hostName');
        var textArea = _ember['default'].$('#meeting-outbound-message-textarea');
        textArea.val(text);
        textArea[0].setSelectionRange(firstLine.length, firstLine.length + temporaryText.length);
      },

      // RECEIVER ACTIONS

      receiverClickSkype: function receiverClickSkype() {
        this.send('handleContactClick', 'skype');
      },
      receiverClickHangouts: function receiverClickHangouts() {
        this.send('handleContactClick', 'hangouts');
      },
      receiverClickPhone: function receiverClickPhone() {
        this.send('handleContactClick', 'phone');
      },
      receiverClickOther: function receiverClickOther() {
        this.send('handleContactClick', 'other');
      },

      handleContactClick: function handleContactClick(type) {
        var req = this.modelFor('request');
        req.set('contactDetails.receiverSelection', type);
        req.save();
      },

      doAccept: function doAccept() {
        this.segment.trackEvent('Confirmation (Indi): Button - Accept changes');
        if (this.controller.get('receiverPreFlight')) {
          this.send('saveAndSend', 'accept_changes', 'request.complete');
          this.segment.trackEvent('Confirmation (Indi): Event - Accept changes success');
        } else {
          this.segment.trackEvent('Confirmation (Indi): Warning - Accept changes failed (incomplete)');
          alert('Please provide answers to the cards highlighted in red.');
        }
      }

    }
  });
});