define('foogi-ember-client/pods/request/edit/time/modals/timezone/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    needs: ['request'],
    types: ['(cities)'],
    meetingTimezone: _ember['default'].computed.alias("controllers.request.model.meetingTimezone"),
    displayTimezone: _ember['default'].computed.alias("controllers.request.model.displayTimezone"),
    timeZoneName: _ember['default'].computed.alias("controllers.request.model.timeZoneName"),
    changeTimezone: false,
    changeTimezoneObserver: _ember['default'].observer('changeTimezone', function () {
      var element = _ember['default'].$('.modal-close-button');
      if (this.get('changeTimezone') && !this.get('displayTimezone')) {
        element.hide();
      } else {
        element.show();
      }
    }),
    okText: _ember['default'].computed('changeTimezone', function () {
      return this.get('changeTimezone') ? "Continue" : "Yes";
    }),
    closeText: _ember['default'].computed('changeTimezone', function () {
      return this.get('changeTimezone') ? _ember['default'].$('') : "Change";
    }),
    actions: {
      changeTimezone: function changeTimezone() {
        this.toggleProperty('changeTimezone');
      },

      setDisplayTimezone: function setDisplayTimezone() {
        if (!this.get('changeTimezone')) {
          return true;
        } else if (this.get('changeTimezone') && this.get('displayTimezone')) {
          return true;
        } else {
          return false;
        }
      }
    }

  });
});