define("foogi-ember-client/templates/components/loading-spokes", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/templates/components/loading-spokes.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "id", "loading");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(0 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(45 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.125s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(90 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.25s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(135 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.375s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(180 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.5s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(225 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.675s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(270 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.75s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".1");
        dom.setAttribute(el2, "d", "M14 0 H18 V8 H14 z");
        dom.setAttribute(el2, "transform", "rotate(315 16 16)");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "opacity");
        dom.setAttribute(el3, "from", "1");
        dom.setAttribute(el3, "to", ".1");
        dom.setAttribute(el3, "dur", "1s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.875s");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "loadingSvgSize", ["loc", [null, [1, 81], [1, 95]]]]], ["attribute", "height", ["get", "loadingSvgSize", ["loc", [null, [1, 107], [1, 121]]]]], ["attribute", "fill", ["get", "loadingSvgColor", ["loc", [null, [1, 131], [1, 146]]]]]],
      locals: [],
      templates: []
    };
  })());
});