define('foogi-ember-client/pods/components/modal-onboarding/component', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        show: (function () {
            this.$('.modal').modal();
        }).on('didInsertElement'),

        actions: {
            showAuthPopup: function showAuthPopup() {
                _ember['default'].$('#walkThroughModal').click();
                _ember['default'].run.next(this, function () {
                    _ember['default'].$('#auth-popup').dropdown('toggle');
                });
            }
        }
    });
});