define('foogi-ember-client/initializers/ember-feature-flags', ['exports', 'foogi-ember-client/config/environment', 'foogi-ember-client/features/-main'], function (exports, _foogiEmberClientConfigEnvironment, _foogiEmberClientFeaturesMain) {
  exports.initialize = initialize;

  function initialize(container, application) {
    var serviceName = _foogiEmberClientConfigEnvironment['default'].featureFlagsService || 'features';
    application.register('features:-main', _foogiEmberClientFeaturesMain['default']);
    application.inject('route', serviceName, 'features:-main');
    application.inject('controller', serviceName, 'features:-main');
    application.inject('component', serviceName, 'features:-main');
    application.inject('features:-main', 'application', 'application:main');
  }

  exports['default'] = {
    name: 'ember-feature-flags',
    initialize: initialize
  };
});