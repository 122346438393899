define('foogi-ember-client/pods/new/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var Route = _ember['default'].Route;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    handleNew: function handleNew(online, phoneCall) {
      var controller = this.controllerFor(this.routeName);

      var parameters = {
        receiverDisplayName: controller.get('receiverDisplayName'),
        receiverEmail: controller.get('receiverEmail'),
        meetingSummary: controller.get('meetingSummary'),
        meetingOutboundMessage: controller.get('meetingOutboundMessage'),
        meetingOnline: online,
        meetingPhoneCall: phoneCall

      };

      this.send('createRequest', parameters);
    },

    actions: {
      newRequestOL: function newRequestOL() {
        this.segment.trackEvent('New meeting: Button - online');
        this.handleNew(true, false);
      },
      newRequestPC: function newRequestPC() {
        this.segment.trackEvent('New meeting: Button - phone call');
        this.handleNew(true, true);
      },
      newRequestFTF: function newRequestFTF() {
        this.segment.trackEvent('New meeting: Button - f2f');
        this.handleNew(false, false);
      },

      back: function back() {
        this.transitionTo('index');
      }
    }
  });
});