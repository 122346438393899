define('foogi-ember-client/pods/request/edit/invitee/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-edit-return-to'], function (exports, _ember, _foogiEmberClientMixinsRequestEditReturnTo) {
	exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestEditReturnTo['default'], {
		// TODO This is just a copy of the wizard page. replace with something better
		actions: {
			clearLocation: function clearLocation() {
				var req = this.modelFor(this.routeName);
				req.set('googlePlaceObject', null);
				req.set('locName', null);
				req.set('locPlaceId', null);
				req.set('locAddress', null);
				req.set('locLat', null);
				req.set('locLng', null);
			}
		}
	});
});