define('foogi-ember-client/pods/components/day-col/component', ['exports', 'ember', 'foogi-ember-client/utils/moment-round-to', 'foogi-ember-client/constants'], function (exports, _ember, _foogiEmberClientUtilsMomentRoundTo, _foogiEmberClientConstants) {
  var HourHeight = _foogiEmberClientConstants['default'].HourHeight;
  var SnapMinutes = _foogiEmberClientConstants['default'].SnapMinutes;
  var MinutesInHour = _foogiEmberClientConstants['default'].MinutesInHour;
  var MinutesInDay = _foogiEmberClientConstants['default'].MinutesInDay;

  // const SnapUnit = parseInt(HourHeight/(MinutesInHour/SnapMinutes)); 

  var MiddleOfWeek = 3;

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  exports['default'] = Component.extend({
    dayColMobile: null,
    classNames: ['col', 'dayCol'],
    classNameBindings: ['threeWide:threeWide'],

    now: computed('clock.minute', function () {
      return moment();
    }),

    busyBlocksVisible: false,
    threeWide: false,

    day: null,
    date: alias('day.date'),
    displayTimezone: null,

    duration: null,

    meetingTime: null,
    hoverTime: null,

    userIsOwner: false,

    // Meeting Blocks
    mousePos: -1,
    hoverVisible: false,

    authenticated: false,
    dismissedCCB: false,

    showConnectCalendarsBlock: computed('authenticated', 'date', 'dismissedCCB', function () {
      var dismissed = this.get('dismissedCCB');
      var authenticated = this.get('authenticated');
      var date = this.get('date');

      if (date === null || authenticated || dismissed) {
        return false;
      }

      return date.weekday() === MiddleOfWeek;
    }),

    meetingVisible: computed('meetingTime', 'date', function () {
      var time = this.get('meetingTime');
      var date = this.get('date');

      return time && date && date.isSame(time, 'day');
    }),

    busyBlocks: alias('day.busyBlocks'),
    unavailableBlocks: alias('day.unavailableBlocks'),
    backgroundColor: alias('event.backgroundColor'), // event is a hash, not an ember data model

    validHoverTime: computed('day.available', 'hoverTime.unix', 'duration', function () {
      var available = this.get('day.available');
      if (!available) {
        return false;
      }

      var day = this.get('day');
      var hoverTime = this.get('hoverTime');
      var duration = this.get('duration');
      var hoverTimeEnd = moment(hoverTime).add(duration, 'minutes');

      // Do we have times to test with?
      if (hoverTime === null || hoverTimeEnd === null) {
        return false;
      }

      // Are they valid moment()s?
      if (!hoverTime.isValid() || !hoverTimeEnd.isValid()) {
        return false;
      }

      // Is the hover time in the past?
      var now = this.get('now');
      if (hoverTime.isBefore(now)) {
        return false;
      }

      // If the selection ends after today we also need to test tomorrow
      // TODO: send event to test tomorrow

      // if (hoverTimeEnd.isAfter(day)) {
      // }

      // If the day is inactive (out of range) or closed (due to availability) it's not valid
      if (day.dayIsClosed()) {
        return false;
      }

      // If I own the request, I can book wherever the hell I like
      var isOwner = this.get('userIsOwner');
      if (isOwner) {
        return true;
      }

      // Otherwise, Test the time for overlaps. If none it's valid.
      return day.validTime(hoverTime, hoverTimeEnd);
    }),

    _setHoverTime: function _setHoverTime(positionY) {

      var minutes = parseInt(positionY / HourHeight * MinutesInHour);
      if (minutes < 0 || minutes > MinutesInDay) {
        return; // hovering out of range
      }

      var hoverTime = this.get('hoverTime');
      if (hoverTime === null) {
        // if there isn't already a hovertime for today, make one by copying the day object
        hoverTime = moment(this.get('date'));
      }

      // Add the minutes onto the current day to get hovertime
      hoverTime = hoverTime.startOf('day').minute(minutes);
      (0, _foogiEmberClientUtilsMomentRoundTo['default'])(hoverTime, 'minute', SnapMinutes, 'down');
      this.set('hoverTime', null); // Property doesn't automatically refresh because we're recycling the moment
      this.set('hoverTime', hoverTime);
    },

    _formatTime: function _formatTime(time) {
      return time ? time.format('h:mmA') : null;
    },

    actions: {
      resetMeetingTimes: function resetMeetingTimes() {
        this.sendAction('resetMeetingTimes');
      },

      jumpWeekPrev: function jumpWeekPrev() {
        this.sendAction('jumpWeekPrev');
      },
      jumpWeekNext: function jumpWeekNext() {
        this.sendAction('jumpWeekNext');
      },

      // Receive updates about the mouse moving around from lower-level components
      trackMouse: function trackMouse(event) {
        if (event !== undefined) {
          var positionY = event.offsetY;
          this.set('mousePos', positionY);
          this._setHoverTime(positionY);
          // this.send('trackMouse', hoverTime);
        }
      },
      mouseEnter: function mouseEnter() {
        this.set('hoverVisible', true);
      },
      mouseLeave: function mouseLeave() {
        this.set('hoverVisible', false);
      },

      placeMeeting: function placeMeeting(event) {
        this.send('trackMouse', event);
        var hoverTime = this.get('hoverTime');
        var validTime = this.get('validHoverTime');
        if (validTime) {
          this.sendAction('placeMeeting', hoverTime, this.get('day'));
        } else {
          alert(this._formatTime(hoverTime) + " isn't a valid time, please choose another that doesn't overlap with busy or unavailable periods");
        }
      },

      // authenticate: function() {
      //   this.sendAction('authenticate');
      // },
      showAuthPopup: function showAuthPopup() {
        _ember['default'].$('#walkThroughModal').click();
        _ember['default'].run.next(this, function () {
          _ember['default'].$('#auth-popup').dropdown('toggle');
        });
      },

      dismissBlock: function dismissBlock() {
        this.set('dismissedCCB', true);
      }

    }
  });
});
/* global moment */