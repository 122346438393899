define('foogi-ember-client/pods/request/edit/wizard/route', ['exports', 'ember', 'foogi-ember-client/mixins/wizard-progress'], function (exports, _ember, _foogiEmberClientMixinsWizardProgress) {

  // The wizard pages should be thought of as a _branching, doubly linked list_, with each page being a node.
  // This can all be implemented at the route level, using the afterModel hooks to handle the branching
  // and enumeration (with the controller maintaining state), but that is a lot of transitioning and wasted cycles.
  // Instead, the node- (route-)selection logic should be handled here at the top of the wizard hierarchy, and just
  // transitioning to the resolved route at the end

  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsWizardProgress['default'], {
    //afterModel: function () {
    //  var step = this.progress();
    //}
  });
});