define('foogi-ember-client/initializers/asset-map', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(application) {
    application.deferReadiness();

    var AssetMap = _ember['default'].Object.extend();

    var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
      _ember['default'].$.getJSON('assets/assetMap.json', resolve).fail(reject);
    });

    promise.then(function (assetMap) {
      AssetMap.reopen({
        assetMap: assetMap,
        resolve: function resolve(name) {
          return assetMap.assets[name];
        }
      });
    }, function () {
      AssetMap.reopen({
        resolve: function resolve(name) {
          return name;
        }
      });
    }).then(function () {
      application.register('assetMap:main', AssetMap, { singleton: true });
      application.inject('controller', 'assets', 'assetMap:main');
      application.inject('component', 'assets', 'assetMap:main');
      application.advanceReadiness();
    });
  }

  exports['default'] = {
    name: 'asset-map',
    initialize: initialize
  };
});