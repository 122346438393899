define('foogi-ember-client/pods/components/time-line/component', ['exports', 'ember', 'foogi-ember-client/constants'], function (exports, _ember, _foogiEmberClientConstants) {
	var HourHeight = _foogiEmberClientConstants['default'].HourHeight;
	var MinutesInHour = _foogiEmberClientConstants['default'].MinutesInHour;
	exports['default'] = _ember['default'].Component.extend({
		classNames: ['no-height'],
		time: null,
		displayTimezone: null,
		updateRealtime: true,

		_timeToHeight: function _timeToHeight(time) {
			var start = moment(time).startOf('day');
			return moment(time).diff(start, 'minutes') * HourHeight / MinutesInHour;
		},

		// Update current time and timeline
		// TODO: discuss/fix potential bug where time rolls over to the next week
		// while drawing weeks. Perhaps just ignore and let users refresh?
		_updateTime: (function () {
			if (this.get('updateRealtime')) {
				var displayTimezone = this.get('displayTimezone');
				var currTime;
				var midnight = moment().startOf('day');

				if (_ember['default'].isPresent(displayTimezone)) {
					currTime = moment().tz(displayTimezone);
				} else {
					currTime = moment();
				}

				this.set('time', currTime.format('LT'));

				if (currTime >= midnight && currTime <= moment().hours(0).minutes(15)) {
					this._setHeightTimeLineMidnight(this._timeToHeight(currTime));
				} else {
					this._setHeightTimeLine(this._timeToHeight(currTime));
				}

				_ember['default'].run.later(this, function () {
					// Runtime loop
					this._updateTime();
				}, 1000); // Update every second
			}
		}).on('didInsertElement'),

		_setHeightTimeLine: function _setHeightTimeLine(height) {
			_ember['default'].$('#now-line').css("top", height - 10 + "px");
			_ember['default'].$('#now-line').removeClass('midnight');
		},

		_setHeightTimeLineMidnight: function _setHeightTimeLineMidnight(height) {
			_ember['default'].$('#now-line').css("top", height + "px");
			_ember['default'].$('#now-line').addClass('midnight');
		},

		willDestroyElement: function willDestroyElement() {
			// stop the live time update
			this.set('updateRealtime', false);
		}
	});
});
/* global moment */