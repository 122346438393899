define('foogi-ember-client/services/-gestures', ['exports', 'ember', 'foogi-ember-client/config/environment', 'ember-gestures/services/-gestures'], function (exports, _ember, _foogiEmberClientConfigEnvironment, _emberGesturesServicesGestures) {

  var gestures = _ember['default'].merge({}, {
    useCapture: false
  });
  gestures = _ember['default'].merge(gestures, _foogiEmberClientConfigEnvironment['default'].gestures);

  exports['default'] = _emberGesturesServicesGestures['default'].extend({
    useCapture: gestures.useCapture
  });
});