define('foogi-ember-client/constants', ['exports'], function (exports) {
    exports['default'] = {
        HourHeight: 48,
        SnapMinutes: 15,

        SecondsInHour: 3600,
        MinutesInHour: 60,
        SecondsInDay: 86400,
        MinutesInDay: 1440,

        MobileMediaQuery: '(max-width: 767px)',

        FullWeekLength: 7,
        CondensedWeekLength: 3,
        MobileJumpLength: 1
    };
});