define('foogi-ember-client/pods/request/show/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    actions: {
      //  SENDER ACTIONS
      back: function back() {
        this.transitionTo('index');
      }
    }
  });
});