define('foogi-ember-client/instance-initializers/segmentio', ['exports', 'ember-cli-segment/instance-initializer', 'foogi-ember-client/config/environment'], function (exports, _emberCliSegmentInstanceInitializer, _foogiEmberClientConfigEnvironment) {
  exports['default'] = {
    name: 'segment',
    initialize: function initialize(applicationInstance) {
      var segment = applicationInstance.lookup('service:segment');

      segment.set('config', _foogiEmberClientConfigEnvironment['default']);
      _emberCliSegmentInstanceInitializer['default'].apply(undefined, arguments);
    }
  };
});