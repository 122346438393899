define('foogi-ember-client/pods/login/register-interest/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    pageTitle: "Welcome to Foogi (Preview Release)",

    submitted: false,

    noProvider: _ember['default'].computed('model.iCloud', 'model.exchange', 'model.o365', 'model.yahoo', 'model.linkedIn', 'model.facebook', 'model.other', function () {
      return !(this.get('model.iCloud') || this.get('model.exchange') || this.get('model.o365') || this.get('model.yahoo') || this.get('model.linkedIn') || this.get('model.facebook') || this.get('model.other'));
    })
  });
});