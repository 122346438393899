define('foogi-ember-client/pods/components/cal-mouse-field/component', ['exports', 'ember', 'ember-gestures/mixins/recognizers'], function (exports, _ember, _emberGesturesMixinsRecognizers) {
  exports['default'] = _ember['default'].Component.extend(_emberGesturesMixinsRecognizers['default'], {
    recognizers: 'swipe', // tap',

    classNames: ['cal-mouse-field'],

    swipeRight: function swipeRight(ev, view) {
      this.attrs.jumpWeekPrev();
    },

    swipeLeft: function swipeLeft(ev, view) {
      this.attrs.jumpWeekNext();
    },

    // tap(ev, view) {
    //   Ember.Logger.debug('tappy!')
    // },

    mouseEnter: function mouseEnter(ev, view) {
      this.attrs.mouseEnter(ev);
    },

    mouseLeave: function mouseLeave(ev, view) {
      this.attrs.mouseLeave(ev);
    },
    mouseMove: function mouseMove(ev, view) {
      // firefox offset hack
      if (typeof ev.offsetX === "undefined" || typeof ev.offsetY === "undefined") {
        var targetOffset = _ember['default'].$(ev.target).offset();
        ev.offsetX = ev.pageX - targetOffset.left;
        ev.offsetY = ev.pageY - targetOffset.top;
      }
      this.attrs.trackMouse(ev);
    },

    // TODO: fix this for iOS
    mouseDown: function mouseDown(ev, view) {
      this.attrs.mouseLeave(ev);

      // firefox offset hack
      if (typeof ev.offsetX === "undefined" || typeof ev.offsetY === "undefined") {
        var targetOffset = _ember['default'].$(ev.target).offset();
        ev.offsetX = ev.pageX - targetOffset.left;
        ev.offsetY = ev.pageY - targetOffset.top;
      }
      this.attrs.droppedMeetingBlock(ev);
    }

  });
});