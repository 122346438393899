define('foogi-ember-client/pods/components/meeting-block/component', ['exports', 'ember', 'foogi-ember-client/mixins/utility-functions', 'foogi-ember-client/constants'], function (exports, _ember, _foogiEmberClientMixinsUtilityFunctions, _foogiEmberClientConstants) {
  var HourHeight = _foogiEmberClientConstants['default'].HourHeight;
  var SnapMinutes = _foogiEmberClientConstants['default'].SnapMinutes;
  var SecondsInHour = _foogiEmberClientConstants['default'].SecondsInHour;
  var MinutesInHour = _foogiEmberClientConstants['default'].MinutesInHour;
  var SecondsInDay = _foogiEmberClientConstants['default'].SecondsInDay;
  var MinutesInDay = _foogiEmberClientConstants['default'].MinutesInDay;
  exports['default'] = _ember['default'].Component.extend(_foogiEmberClientMixinsUtilityFunctions['default'], {
    hoverBlock: false,
    updateTrigger: null,

    time: null,
    duration: null,
    valid: false,
    buffer: 30,

    timezoneSender: null,
    timezoneReceiver: null,

    // request: null,
    // userIsOwner: null,

    showReceiverTimezone: _ember['default'].computed.and('request.meetingOnline', 'request.senderReceiverTzDifferent'),

    timeFormatted: _ember['default'].computed('updateTrigger', function () {
      return this._formatTime(this.get('time'));
    }),

    receiverTime: _ember['default'].computed('updateTrigger', 'time', 'timezoneReceiver', function () {
      return this.formatTimeInTz('time', 'timezoneReceiver', 'LT ddd');
    }),

    senderTime: _ember['default'].computed('updateTrigger', 'time', 'timezoneSender', function () {
      return this.formatTimeInTz('time', 'timezoneSender', 'LT ddd');
    }),

    posTop: _ember['default'].computed('updateTrigger', function () {
      var time = this.get('time');
      if (time === null) {
        return 0;
      }
      var minutesFromTop = moment(time).diff(moment(time).startOf('day'), 'minutes');
      return this._minutesToPixels(minutesFromTop);
    }),

    posHeight: _ember['default'].computed('duration', function () {
      return this._minutesToPixels(this.get('duration'));
    }),

    bufferHeight: _ember['default'].computed('buffer', function () {
      return this._minutesToPixels(this.get('buffer'));
    }),

    style: _ember['default'].computed('visible', 'posTop', 'posHeight', function () {
      var visible = true;
      var top = parseInt(this.get('posTop'));
      var height = parseInt(this.get('posHeight'));
      return this._styleBlock(visible, top, height);
    }),

    bufferBottomStyle: _ember['default'].computed('posHeight', function () {
      var height = parseInt(this.get('posHeight'));
      return new _ember['default'].String.htmlSafe("height:24px;top:" + height + "px");
    }),

    bufferTopStyle: _ember['default'].computed('bufferHeight', function () {
      var height = parseInt(this.get('bufferHeight'));
      return new _ember['default'].String.htmlSafe("height:24px;top:-" + height + "px");
    }),

    _styleBlock: function _styleBlock(visible, top, height) {
      if (visible) {
        return new _ember['default'].String.htmlSafe("height:" + height + "px;top:" + top + "px;display:block");
      } else {
        return new _ember['default'].String.htmlSafe("display: none");
      }
    },
    _formatTime: function _formatTime(time) {
      return time ? time.format('LT ddd') : null;
    },
    _minutesToPixels: function _minutesToPixels(minutes) {
      return parseInt(minutes / MinutesInHour * HourHeight);
    }

  });
});