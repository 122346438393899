define('foogi-ember-client/torii-providers/google-token', ['exports', 'torii/configuration', 'torii/providers/oauth2-bearer'], function (exports, _toriiConfiguration, _toriiProvidersOauth2Bearer) {

  var GoogleToken = _toriiProvidersOauth2Bearer['default'].extend({
    name: 'google-token',
    baseUrl: 'https://accounts.google.com/o/oauth2/auth',

    // additional params that this provider requires
    requiredUrlParams: ['state'],
    optionalUrlParams: ['scope', 'request_visible_actions', 'access_type'],

    requestVisibleActions: (0, _toriiConfiguration.configurable)('requestVisibleActions', ''),

    accessType: (0, _toriiConfiguration.configurable)('accessType', ''),

    responseParams: ['code'],

    scope: (0, _toriiConfiguration.configurable)('scope', 'email'),

    state: (0, _toriiConfiguration.configurable)('state', 'STATE'),

    redirectUri: (0, _toriiConfiguration.configurable)('redirectUri', 'http://localhost:4200'),

    open: function open() {

      var url = this.buildUrl(),
          responseParams = this.get('responseParams');
      //var name        = this.get('name'),
      //    redirectUri = this.get('redirectUri'),
      //    client_id = this.get('client_id');

      return this.get('popup').open(url, responseParams).then(function (authData) {
        var missingResponseParams = [];

        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          throw "The response from the provider is missing " + "these required response params: " + responseParams.join(', ');
        }
        // alert(authData);
        // return $.get("https://www.googleapis.com/plus/v1/people/me", {access_token: authData.token}).then(function(user){
        //   return {
        //     userName: user.displayName,
        //     userEmail: user.emails[0].value,
        //     provider: name,
        //     redirectUri: redirectUri
        //   };
        // });
        return authData;
      });
    }
  });

  exports['default'] = GoogleToken;
});