define('foogi-ember-client/pods/request/edit/wizard/who/route', ['exports', 'ember', 'foogi-ember-client/mixins/wizard-navigation'], function (exports, _ember, _foogiEmberClientMixinsWizardNavigation) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsWizardNavigation['default'], {
    actions: {
      focusEmail: function focusEmail() {
        _ember['default'].$('.form-control#email').focus();
      },
      focusLocation: function focusLocation() {
        _ember['default'].$('.google-places-autocomplete .form-control').focus();
      },
      clearLocation: function clearLocation() {
        var req = this.modelFor(this.routeName);
        req.set('googlePlaceObject', null);
        req.set('locName', null);
        req.set('locPlaceId', null);
        req.set('locAddress', null);
        req.set('locLat', null);
        req.set('locLng', null);
      }
    }
  });
});