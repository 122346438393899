define('foogi-ember-client/mixins/request-user-session', ['exports', 'ember'], function (exports, _ember) {
  var Mixin = _ember['default'].Mixin;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = Mixin.create({
    session: inject.service('session'),
    currentUser: inject.service('session-user'),

    displayAsSenderOverride: false, // If this is true, display as the receiver no matter what
    authenticated: computed.alias('session.isAuthenticated'),
    displayAsSender: computed('displayAsSenderOverride', 'currentUser.me', 'request', function () {
      if (this.get('displayAsSenderOverride') === true) {
        return false;
      }
      return this.userIsOwner(this.get('request'));
    }),

    // TODO: make this a lot cleaner with some sort of
    // torii function to check login status
    getLoginStatus: function getLoginStatus() {
      return this.get('session.isAuthenticated');
    },

    userIsOwner: function userIsOwner(request) {
      var currentUser = this.get('currentUser.me');

      if (!this.get('session.isAuthenticated') || !currentUser) {
        return false;
      }

      return currentUser.get('id') === request.get('user.id');
    },

    transitionBasedOnOwnership: function transitionBasedOnOwnership(source, model, ownerDestination, otherDestination) {
      // Choose which page to transition to based off login status

      if (this.getLoginStatus()) {
        if (source.userIsOwner(model)) {
          source.transitionTo(ownerDestination, model.id);
        } else {
          source.transitionTo(otherDestination, model.id);
        }
      }
    }
  });
});