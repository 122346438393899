define('foogi-ember-client/pods/calendar-event/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    calendar: belongsTo('calendar'),
    day: belongsTo('day'),

    allDay: attr('boolean'),
    busy: attr('boolean'),

    summary: attr('string'),
    description: attr('string'),

    time_start: attr('number'),
    time_end: attr('number'),
    duration: attr('number'),

    location: attr('string'),

    remote_id: attr('string'),
    status: attr('string'),

    visible: alias('calendar.visible'),
    backgroundColor: alias('calendar.backgroundColor'),
    foregroundColor: alias('calendar.foregroundColor')
  });
});