define('foogi-ember-client/pods/meta-calendar/model', ['exports', 'ember', 'ember-data', 'foogi-ember-client/pods/day-blank/model'], function (exports, _ember, _emberData, _foogiEmberClientPodsDayBlankModel) {

  var cp = _ember['default'].computed;
  var alias = cp.alias;

  var isBlank = _ember['default'].isBlank;
  var isPresent = _ember['default'].isPresent;

  exports['default'] = _emberData['default'].Model.extend({

    ready: false,

    token: _emberData['default'].attr('string'),

    dateStart: _emberData['default'].attr('utc'),
    dateEnd: _emberData['default'].attr('utc'),

    tzSource: _emberData['default'].attr('string'),
    tzTarget: _emberData['default'].attr('string'),

    _days: _ember['default'].A(),
    // days: Ember.RSVP.all(function() {
    //     return this.store.findAll('day', token),
    // })
    // days: DS.belongsTo('day', {async: false}),

    days: null,

    _busyBlocks: null,
    _filterRules: null,
    _calEvents: null,

    timeRequest: _emberData['default'].belongsTo('timeRequest', { async: true }),

    now: cp('clock.minute', 'tzTarget', function () {
      var tzTarget = this.get('tzTarget');
      if (isPresent(tzTarget)) {
        return moment().tz(tzTarget);
      } else {
        return moment();
      }
    }),

    startOfToday: cp('now', function () {
      return this.get('now').startOf('day');
    }),

    tzSame: cp.equal('tzSource', 'tzTarget'),

    // Simply: East->Early, West->Late. but be careful, In summer Brisbane is later than Sydney yet still further east
    // These may have trouble dealing with brisbane/sydney drifting in and out of sync across daylight savings
    padDatePast: cp.alias('tzTargetStartsEarlier'),
    tzTargetStartsEarlier: cp('tzSource', 'tzTarget', function () {
      var tzSource = this.get('tzSource');
      var tzTarget = this.get('tzTarget');
      var dateStart = this.get('dateStart');
      var s = moment(dateStart).tz(tzSource).startOf('day');
      var t = moment(dateStart).tz(tzTarget).startOf('day');
      return false;
    }),

    padDateFuture: cp.alias('tzTargetEndsLater'),
    tzTargetEndsLater: cp('tzSource', 'tzTarget', function () {
      var tzSource = this.get('tzSource');
      var tzTarget = this.get('tzTarget');
      var dateEnd = this.get('dateEnd');
      var s = moment(dateEnd).tz(tzSource).startOf('day');
      var t = moment(this.get('dateEnd')).tz(tzTarget).startOf('day');
      return false;
    }),

    targetDayFirst: cp('now', 'dateStart', 'tzTarget', 'padDatePast', function () {
      return moment(this.get('days').objectAt(0).get('date'));

      var offset = 0;
      var result = null;

      var dateStart = this.get('dateStart');
      var now = this.get('now');
      var tzTarget = this.get('tzTarget');
      var padDatePast = this.get('padDatePast');

      // Don't die if the critical information isn't present
      if (isBlank(dateStart) || isBlank(tzTarget)) {
        return null;
      }

      if (padDatePast) {
        offset--;
      }

      if (dateStart.isBefore(now.startOf('day'))) {
        result = now;
      } else {
        result = dateStart;
      }

      // return moment(result).add(offset, 'days').tz(tzTarget).startOf('day');
    }),

    targetDayLast: cp('now', 'dateStart', 'dateEnd', 'tzTarget', 'padDateFuture', function () {
      var offset = 0;
      var result = null;

      var dateStart = this.get('dateStart');
      var dateEnd = this.get('dateEnd');

      var now = this.get('now');
      var tzTarget = this.get('tzTarget');
      var padDateFuture = this.get('padDateFuture');

      // Don't die if the critical information isn't present
      if (isBlank(dateEnd) || isBlank(tzTarget)) {
        return null;
      }

      if (padDateFuture) {
        offset++;
      }

      if (dateEnd.isBefore(now.startOf('day'))) {
        result = now;
      } else {
        result = dateEnd;
      }

      var offsetEnd = moment.tz(result, tzTarget).add(offset, 'days').startOf('day');
      return offsetEnd;
    }),

    numDays: alias('windowLength'),
    windowLength: cp('targetDayFirst', 'targetDayLast', function () {
      var targetDayFirst = this.get('targetDayFirst');
      var targetDayLast = this.get('targetDayFirst');
      var now = this.get('now');

      if (targetDayLast.isBefore(now)) {
        // If our booking window has elapsed...
        return 0;
      } else {
        return targetDayFirst.twix(targetDayLast).count('days');
      }
    }),

    // getDays: function(first, number) {
    //   var firstOffset = _dayOffset(first); // buhhh
    //   maxOffset = this.get('numDays') - 1; //
    // },

    // given a moment() representing a date in the target timezone
    // - build days up to that date if it doesn't exist
    // - fetch and return that day
    getDay: function getDay(date) {

      // Range checking
      if (date.isBefore(this.get('startOfToday'))) {
        var tzTarget = this.get('tzTarget');
        return _foogiEmberClientPodsDayBlankModel['default'].create({ date: date, displayTimezone: tzTarget, active: false }); // return a blank, blocked out day    
      }

      if (this._dateInvalid(date)) {
        Ember.Logger.debug("Date " + date.format() + " out of range, returning inactive day");
        var tzTarget = this.get('tzTarget');
        return _foogiEmberClientPodsDayBlankModel['default'].create({ date: date, displayTimezone: tzTarget, active: false }); // return a blank, blocked out day
      }
      // End range Checking

      var index = this._dateToIdx(date);

      // Is that day built yet?
      var days = this.get('days');
      if (isBlank(days) || days.length <= index) {
        this._buildDays(index);
      }
      return this.get('days').objectAt(index);
    },

    // Tests to see if a given date falls within our window
    _dateInvalid: function _dateInvalid(testDate) {
      var targetDayFirst = this.get('targetDayFirst');
      var targetDayLast = this.get('targetDayLast');
      if (testDate.isBefore(targetDayFirst) || testDate.isAfter(targetDayLast)) {
        Ember.Logger.debug("date " + testDate.format() + " not in range " + targetDayFirst.format() + "-" + targetDayLast.format());
        return true;
      }
      return false;
    },

    // Converts an index of the Days array into its corresponding date.
    // No range checking is performed
    _idxToDate: function _idxToDate(idx) {
      var targetDayFirst = this.get('targetDayFirst');
      var date = moment(targetDayFirst).add(idx, 'days');
      return date;
    },

    // Converts a date into its corresponding index of the Days array.
    // No range checking is performed
    _dateToIdx: function _dateToIdx(date) {
      var targetDayFirst = this.get('targetDayFirst');
      var idx = targetDayFirst.twix(date).count('days') - 1; //Minus 1 because twix always is over by 1
      return idx;
    },

    _buildDays: function _buildDays(lastIndex) {
      var days = this.get('days');
      var startingLength = days.length;

      var targetDayFirst = this.get('targetDayFirst');
      // var now = this.get('now');
      var tzTarget = this.get('tzTarget');

      var newDay = null;

      for (var i = startingLength; i <= lastIndex; i++) {
        var startOfDay = moment(targetDayFirst).add(i, 'days');
        var endOfDay = moment(startOfDay).endOf('day');

        newDay = _foogiEmberClientPodsDayBlankModel['default'].create({ date: startOfDay, displayTimezone: tzTarget });
        this._processBusyBlocks(newDay, startOfDay, endOfDay);
        // this._processAvailableBlocks(newDay, startOfDay endOfDay, index);
        // this._processMeetingBlocks(newDay, startOfDay endOfDay);
        // this._processCalendarBlocks(newDay, startOfDay endOfDay);

        days.pushObject(newDay);
      }
    },

    // TODO fix this shit
    _processBusyBlocks: function _processBusyBlocks(day, date, endDate) {
      // var blocks = this._randomBlocks(date, endDate);
      var blocks = new _ember['default'].A(); // TODO Stub

      var activeDayBlocks = new _ember['default'].A();
      blocks.forEach(function (block) {
        activeDayBlocks.pushObject(block);
      });

      day.setProperties({
        busyBlocks: activeDayBlocks
      });
    },

    // TODO: refactor
    _processAvailableBlocks: function _processAvailableBlocks(day, date, endDate, index) {
      var _this = this;

      var availableBlocks = this.get('availableBlocks');
      // var availableBlocksUpdated = this.get('availableBlocksUpdated');
      // var prevBlock = null;

      if (availableBlocks && !day.get('unavailableBlocksPopulated')) {
        // || availableBlocksUpdated) {
        var unavailableBlocks = _ember['default'].A();
        availableBlocks.forEach(function (availableBlock, availableBlockIndex, availableBlocks, date, unavailableBlocks, index) {
          _this._doAvailibleBlockThing(availableBlock, availableBlockIndex, availableBlocks);
        });
        day.setProperties({
          unavailableBlocks: unavailableBlocks,
          unavailableBlocksPopulated: true
        });
        unavailableBlocks = _ember['default'].A();
      }
    },

    _doAvailibleBlockThing: function _doAvailibleBlockThing(availableBlock, availableBlockIndex, availableBlocks, date, unavailableBlocks, index) {
      var prevBlock = null;
      if (index > 0) {
        prevBlock = availableBlocks[availableBlockIndex - 1];
      }

      var nextBlock = availableBlocks[availableBlockIndex + 1];
      var start = this._zoneTime(moment(availableBlock.timeStart));
      var end = this._zoneTime(moment(availableBlock.timeEnd));
      var nextBlockStart = nextBlock ? this._zoneTime(moment(nextBlock.timeStart)) : null;
      //var prevBlockStart = prevBlock ? this._zoneTime(moment(prevBlock.timeStart)) : moment(start).startOf('day');
      //var availableRange = moment().range(start, end);
      var nextBlockRange = nextBlockStart ? moment().range(moment(end), moment(nextBlockStart)) : null;
      var dayRange = moment().range(moment(date), moment(date).endOf('day'));
      var currentTime = this._zoneTime(moment());
      var currentTimeStartDay = moment(currentTime).startOf('day');
      var firstWeek = this.get('activeWeekIndex') === 0;
      var firstAvailableBlock = availableBlockIndex === 0;
      var duration = null;

      // runs once for each day up to current day
      if (this.get('activeWeekIndex') === 0 && availableBlockIndex === 0 && date < currentTimeStartDay) {
        var timeEnd = moment(date).endOf('day');
        unavailableBlocks.pushObject(_ember['default'].Object.create({
          timeStart: moment(date),
          timeEnd: timeEnd,
          duration: moment.duration(moment(timeEnd) - moment(date)).asMinutes()
        }));
      }

      // handle blackout to first Available Block Start
      if (firstWeek && firstAvailableBlock) {
        duration = moment.duration(moment(start) - moment(date)).asMinutes();
        if (duration > 0) {
          unavailableBlocks.pushObject(_ember['default'].Object.create({
            timeStart: moment(date),
            timeEnd: moment(start),
            duration: duration
          }));
        }
      }

      // handle blackout up to currentTime
      if (firstWeek && firstAvailableBlock && dayRange.contains(currentTime)) {
        unavailableBlocks.pushObject(_ember['default'].Object.create({
          timeStart: moment(date),
          timeEnd: moment(currentTime),
          duration: moment.duration(moment(currentTime) - moment(date)).asMinutes()
        }));
      }

      if (!nextBlock) {
        // handles the last block
        unavailableBlocks.pushObject(_ember['default'].Object.create({
          timeStart: moment(end),
          timeEnd: moment(date).endOf('day'),
          duration: moment.duration(moment(date).endOf('day') - moment(end)).asMinutes()
        }));
      } else {
        // handles every block except for ones which blackout up to currentTime etc
        duration = moment.duration(moment(nextBlockStart) - moment(end)).asMinutes();
        if (duration > 0) {
          if (nextBlockRange.overlaps(dayRange)) {
            unavailableBlocks.pushObject(_ember['default'].Object.create({
              timeStart: moment(end),
              timeEnd: moment(nextBlockStart),
              duration: moment.duration(moment(nextBlockStart) - moment(end)).asMinutes()
            }));
          }
        }
      }
    },

    _processCalendarBlocks: function _processCalendarBlocks(day, date, endDate) {
      var calendars = this.get('calendars');

      if (calendars) {
        if (!day.get('eventsPopulated') || this.get('eventsUpdated')) {
          Ember.Logger.debug('populating events');
          var eventColors = this.get('colors.event');

          var calFG = '#1d1d1d';
          var calBG = '#a4bdfc';

          var activeDayEvents = new _ember['default'].A();
          calendars.forEach(function (calendar) {});

          day.setProperties({
            eventsPopulated: true,
            events: activeDayEvents
          });
        }
      }
    },

    _processCalendar: function _processCalendar(calendar) {
      var _this2 = this;

      var calFG = calendar.calItem.foregroundColor;
      var calBG = calendar.calItem.backgroundColor;

      calendar.events.then(function (events) {
        return _this2._processCalendarEvents(events); //TODO this doesn't belong here
      });
    },

    _processCalendarEvents: function _processCalendarEvents(events) {

      // events.forEach((event) => {
      //   if (event.start.hasOwnProperty('dateTime') &&
      //     event.end.hasOwnProperty('dateTime')) {
      //     var timeStart = this._zoneTime(moment(event.start.dateTime));
      //     var timeEnd = this._zoneTime(moment(event.end.dateTime));

      //     if (timeEnd.isAfter(date) &&
      //         timeStart.isBefore(endDate)) {
      //       activeDayEvents.pushObject(event);
      //     }
      //   }

      //   // Set default event display
      //   event.set('display', calendar.display);

      //   // Set event colour
      //   var color = event.colorId;
      //   if (color) {
      //     event.set('color', Em.Object.create(eventColors.get(color)));
      //   } else {
      //     // Temp for default color (no event.color)
      //     event.set('color', Em.Object.create({background: ""+calBG, foreground: ""+calFG}));
      //   }
      // });

    }

  });
});
/* global moment */

// EVERYTHING THAT LOOKS LIKE A DATE IS EITHER A MOMENT OR NULL.

// DATES refer to things set in the source timezone
// DAYS refer to things applied in the target timezone