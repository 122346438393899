define('foogi-ember-client/torii-providers/ms-graph-oauth2-custom', ['exports', 'torii/providers/oauth2-code', 'torii/configuration'], function (exports, _toriiProvidersOauth2Code, _toriiConfiguration) {
  exports['default'] = _toriiProvidersOauth2Code['default'].extend({

    name: 'ms-graph-oauth2-custom',
    baseUrl: 'https://login.microsoftonline.com/46798379-2ed0-4479-89bd-e561f5d1e50d/oauth2/authorize',

    // additional params that this provider requires
    requiredUrlParams: ['state', 'resource'],
    // optionalUrlParams: ['scope', 'request_visible_actions', 'access_type', 'approval_prompt'],

    // requestVisibleActions: configurable('requestVisibleActions', ''),

    // approvalPrompt: configurable('approvalPrompt', 'force'),

    // accessType: configurable('accessType', ''),
    resource: 'https://graph.microsoft.com/',

    state: (0, _toriiConfiguration.configurable)('state', 'STATE'),

    responseParams: ['code', 'session_state', 'state'],
    responseType: (0, _toriiConfiguration.configurable)('response_type', 'code'),
    // scope: configurable('scope', 'email'),

    redirectUri: (0, _toriiConfiguration.configurable)('redirectUri')

  });
});
/**
 * This class implements authentication against google
 * using the OAuth2 authorization flow in a popup window.
 */