define("foogi-ember-client/transforms/utc", ["exports", "ember-data"], function (exports, _emberData) {
  /* global moment */

  exports["default"] = _emberData["default"].Transform.extend({
    serialize: function serialize(value) {
      return value ? value.toJSON() : null;
    },

    deserialize: function deserialize(value) {
      return moment.utc(value);
    }
  });
});