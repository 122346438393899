define('foogi-ember-client/torii-providers/google-oauth2-custom', ['exports', 'torii/providers/oauth2-code', 'torii/configuration'], function (exports, _toriiProvidersOauth2Code, _toriiConfiguration) {

  var GoogleOauth2Custom = _toriiProvidersOauth2Code['default'].extend({

    name: 'google-oauth2-custom',
    baseUrl: 'https://accounts.google.com/o/oauth2/auth',

    // additional params that this provider requires
    requiredUrlParams: ['state'],
    optionalUrlParams: ['scope', 'request_visible_actions', 'access_type', 'approval_prompt'],

    requestVisibleActions: (0, _toriiConfiguration.configurable)('requestVisibleActions', ''),

    approvalPrompt: (0, _toriiConfiguration.configurable)('approvalPrompt', 'force'),

    accessType: (0, _toriiConfiguration.configurable)('accessType', ''),

    responseParams: ['code'],

    scope: (0, _toriiConfiguration.configurable)('scope', 'email'),

    state: (0, _toriiConfiguration.configurable)('state', 'STATE'),

    redirectUri: (0, _toriiConfiguration.configurable)('redirectUri'),

    open: function open() {
      var name = this.get('name'),
          url = this.buildUrl(),
          redirectUri = this.get('redirectUri'),
          responseParams = this.get('responseParams');

      return this.get('popup').open(url, responseParams).then(function (authData) {
        var missingResponseParams = [];

        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          throw "The response from the provider is missing " + "these required response params: " + responseParams.join(', ');
        }

        return {
          authorizationToken: authData,
          provider: name,
          redirectUri: redirectUri
        };
      });
    }
  });

  exports['default'] = GoogleOauth2Custom;
});
/**
 * This class implements authentication against google
 * using the OAuth2 authorization flow in a popup window.
 */