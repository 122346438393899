define('foogi-ember-client/pods/components/nav-bar/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    classNames: ['foogi-nav-container'],

    session: inject.service(),
    currentUser: inject.service('session-user'),

    user: null,
    notifications: false,

    actions: {
      authenticate: function authenticate() {
        this.sendAction('authenticate');
      },
      logoClick: function logoClick() {
        this.sendAction('logoClick');
      },
      logout: function logout() {
        this.sendAction('logout');
      },
      toggleSidebar: function toggleSidebar() {
        this.sendAction('toggleSidebar');
      },
      rightButtonAction: function rightButtonAction() {
        this.toggleProperty('miniCalOpen');
        this.sendAction('rightButtonAction');
      }
    }
  });
});