define('foogi-ember-client/pods/request/edit/wizard/index/route', ['exports', 'ember', 'foogi-ember-client/mixins/wizard-progress'], function (exports, _ember, _foogiEmberClientMixinsWizardProgress) {

  // The whole point of index is to handle dispatch to the appropriate point in the wizard.
  // This logic could maybe exist in wizard/route instead, or vice versa, but while the hook
  // is in here, we can deliberately trigger it by transitioning to it.
  // I smell a refactor coming to make this an action rather than a transition,
  // but at the moment it works nicely because it comes 'before' the first screen and 'after' the last

  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsWizardProgress['default'], {
    beforeModel: function beforeModel() {
      //var lastRoute = this.controllerFor('request.edit.wizard').get('lastRoute');
      if (this.readyToGo()) {
        // If the wizard is completed, take us to the summary/confirmation
        //this.controllerFor('request.edit.wizard').set('lastRoute', null);
        this.transitionTo('request.confirm');
      } else {
        // If we are just entering the wizard, take us to the furthest step
        var progress = this.progress();
        this.transitionTo(progress);
        //} else { // Else we're leaving the wizard so clear progress and leave
        //  this.controllerFor('request.edit.wizard').set('lastRoute', null);
        //  this.transitionTo('dashboard');
      }
    }
  });
});