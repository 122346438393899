define('foogi-ember-client/pods/request/complete/route', ['exports', 'ember', 'foogi-ember-client/config/environment'], function (exports, _ember, _foogiEmberClientConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    afterModel: function afterModel() {
      var destination = null;
      var receiverDisplayName = this.modelFor('request').get('receiverDisplayName');
      var receiverEmail = this.modelFor('request').get('receiverEmail');
      var token = this.modelFor('request').get('id');
      if (_ember['default'].isPresent(this.get('session.currentUser'))) {
        destination = _foogiEmberClientConfigEnvironment['default'].TRADE_UP_ACCEPTED_FRANKI;
      } else {
        destination = _foogiEmberClientConfigEnvironment['default'].TRADE_UP_ACCEPTED_INDI + '&utm_source=request&utm_medium=portal' + '&utm_term=' + receiverDisplayName + '&utm_campaign=' + receiverEmail + '&utm_content=' + token;
      }
      window.location.replace(_foogiEmberClientConfigEnvironment['default'].TRADE_UP_BASE_URL + destination);
    },

    actions: {
      //  SENDER ACTIONS
      backToDash: function backToDash() {
        this.transitionTo('index');
      }
    }
  });
});