define('foogi-ember-client/pods/day-blank/model', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    date: null,
    displayTimezone: null,
    active: false,
    busyBlocks: null,
    blocksPopulated: true,
    meetings: null,
    meetingsNotPopulated: false,
    events: null,
    eventsNotPopulated: false,
    unavailableBlocks: null,
    unavailableBlocksPopulated: true,
    available: false,
    dateInactive: true,
    dayIsClosed: function dayIsClosed() {
      return true;
    },

    validTime: false,

    hours: _ember['default'].computed('date', function () {
      var hours = _ember['default'].A();
      for (var i = 0; i < 24; i++) {
        hours.pushObject({ available: false });
      }

      return hours;
    }),

    dayName: _ember['default'].computed('date', function () {
      var date = this.get('date');
      return date.format("ddd");
    }),

    dateIsWeekend: _ember['default'].computed('date', function () {
      var name = this.get('dayName');
      return name === 'Sat' || name === 'Sun';
    }),

    dateDoM: _ember['default'].computed('date', function () {
      var date = this.get('date');
      return date.format("D");
    }),

    dateIsPast: _ember['default'].computed('date', function () {
      var date = moment(this.get('date'));
      return date.isBefore(moment().tz(this.get('displayTimezone')), 'day');
    }),

    // dateIsToday: Ember.computed('date', function() {
    //   var date = moment(this.get('date'));
    //   return moment().tz(this.get('displayTimezone')).isSame(date, 'day');
    // }),

    dateIsToday: false,

    timeIsFuture: _ember['default'].computed.not('dateIsPast')
  });
});