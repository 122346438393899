define('foogi-ember-client/pods/request/confirm/controller', ['exports', 'ember', 'foogi-ember-client/config/environment', 'foogi-ember-client/mixins/request-user-session'], function (exports, _ember, _foogiEmberClientConfigEnvironment, _foogiEmberClientMixinsRequestUserSession) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var inject = _ember['default'].inject;
  var not = computed.not;
  var and = computed.and;
  var equal = computed.equal;
  var alias = computed.alias;
  exports['default'] = Controller.extend(_foogiEmberClientMixinsRequestUserSession['default'], {
    application: inject.controller('application'),

    pageTitle: computed('currentUser.me', 'model.id', function () {
      var req = this.get('model');
      return this.userIsOwner(req) ? 'Compose a Message' : 'Lock in your meeting';
    }),

    isLoading: false,
    showMap: false,
    showTime: false,
    showContacts: false,
    timeTabDuration: true,
    isInvalid: true,
    showMeetingNote: computed('model.meetingNotes', function () {
      if (this.get('model.meetingNotes')) {
        return true;
      } else {
        return false;
      }
    }),

    timeMode: computed('model.availabilityTimeMode', function () {
      var time = this.get('model.availabilityTimeMode');
      switch (time) {
        case 'all':
          return 'any time (allday; everyday)';
          break;
        case 'working':
          return 'working hours (9am - 6pm; Mon – Fri)';
          break;
        case 'waking':
          return 'awake hours (6am - 11pm; everyday)';
          break;
      }
    }),
    rangeMode: computed('model.availabilityRangeMode', function () {
      var time = this.get('model.availabilityRangeMode');
      switch (time) {
        case 'any':
          return 'two months';
          break;
        case 'week':
          return 'week';
          break;
        case 'month':
          return 'month';
          break;
      }
    }),
    showReceiverTimezone: and('model.meetingOnline', 'model.senderReceiverTzDifferent'),
    declineURL: computed('model.id', function () {
      var token = this.get('model.id');
      return _foogiEmberClientConfigEnvironment['default'].API.HOST + '/click_through/' + token + '/decline';
    }),

    // Make sure our user has provided valid details for everything so we can proceed
    commonPreFlight: and('model.validRecipient', 'model.validSummary', 'model.validTime', 'model.validLocation'),
    senderPreFlight: and('commonPreFlight', 'model.validOutboundMessage'),
    receiverPreFlight: and('commonPreFlight'),

    highlightErrors: true,

    invalidInvitee: not('model.validRecipient'),
    invalidSummary: not('model.validSummary'),
    invalidTime: not('model.validTime'),
    invalidLocation: not('model.validLocation'),
    invalidSenderContact: not('model.validSenderContactDetails'),
    invalidOutboundMessage: not('model.validOutboundMessage'),

    highlightInvitee: and('invalidInvitee', 'highlightErrors'),
    highlightSummary: and('invalidSummary', 'highlightErrors'),
    highlightTime: and('invalidTime', 'highlightErrors'),
    highlightLocation: and('invalidLocation', 'highlightErrors'),
    highlightSenderContact: and('invalidSenderContact', 'highlightErrors'),
    highlightOutboundMessage: and('invalidOutboundMessage', 'highlightErrors'),

    meetingMessagePlaceholder: computed('model.receiverDisplayName', 'model.hostName', function () {
      return "Compose your message to " + this.get('model.receiverDisplayName') + " here...";
    })
  });
});