define("foogi-ember-client/pods/components/day-col/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 15,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "time-line", [], ["displayTimezone", ["subexpr", "@mut", [["get", "displayTimezone", ["loc", [null, [14, 32], [14, 47]]]]], [], []]], ["loc", [null, [14, 4], [14, 49]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 6
              },
              "end": {
                "line": 21,
                "column": 6
              }
            },
            "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "day-col/visible-block", [], ["timeStart", ["subexpr", "@mut", [["get", "busyBlock.time_start", ["loc", [null, [20, 42], [20, 62]]]]], [], []], "duration", ["subexpr", "@mut", [["get", "busyBlock.duration", ["loc", [null, [20, 72], [20, 90]]]]], [], []], "transparent", false, "caption", "Busy", "busyBlock", true], ["loc", [null, [20, 8], [20, 140]]]]],
          locals: ["busyBlock"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "day.busyBlocks", ["loc", [null, [19, 14], [19, 28]]]]], [], 0, null, ["loc", [null, [19, 6], [21, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "day-col/visible-block", [], ["timeStart", ["subexpr", "@mut", [["get", "unavailableBlock.start", ["loc", [null, [24, 40], [24, 62]]]]], [], []], "duration", ["subexpr", "@mut", [["get", "unavailableBlock.duration", ["loc", [null, [24, 72], [24, 97]]]]], [], []], "transparent", false, "caption", "Unavailable", "busyBlock", true], ["loc", [null, [24, 6], [24, 154]]]]],
        locals: ["unavailableBlock"],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 29,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "meeting-block", [], ["time", ["subexpr", "@mut", [["get", "hoverTime", ["loc", [null, [28, 27], [28, 36]]]]], [], []], "duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [28, 46], [28, 54]]]]], [], []], "valid", ["subexpr", "@mut", [["get", "validHoverTime", ["loc", [null, [28, 61], [28, 75]]]]], [], []], "updateTrigger", ["subexpr", "@mut", [["get", "mousePos", ["loc", [null, [28, 90], [28, 98]]]]], [], []], "hoverBlock", true, "timezoneSender", ["subexpr", "@mut", [["get", "timezoneSender", ["loc", [null, [28, 130], [28, 144]]]]], [], []], "timezoneReceiver", ["subexpr", "@mut", [["get", "timezoneReceiver", ["loc", [null, [28, 162], [28, 178]]]]], [], []], "request", ["subexpr", "@mut", [["get", "request", ["loc", [null, [28, 187], [28, 194]]]]], [], []], "userIsOwner", ["subexpr", "@mut", [["get", "userIsOwner", ["loc", [null, [28, 207], [28, 218]]]]], [], []], "meetingOnline", ["subexpr", "@mut", [["get", "meetingOnline", ["loc", [null, [28, 233], [28, 246]]]]], [], []]], ["loc", [null, [28, 6], [28, 248]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "meeting-block", [], ["time", ["subexpr", "@mut", [["get", "meetingTime", ["loc", [null, [31, 27], [31, 38]]]]], [], []], "duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [31, 48], [31, 56]]]]], [], []], "valid", true, "updateTrigger", ["subexpr", "@mut", [["get", "meetingTime", ["loc", [null, [31, 82], [31, 93]]]]], [], []], "hoverBlock", false, "meetingOnline", ["subexpr", "@mut", [["get", "meetingOnline", ["loc", [null, [31, 125], [31, 138]]]]], [], []]], ["loc", [null, [31, 6], [31, 140]]]]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.6.2",
              "loc": {
                "source": null,
                "start": {
                  "line": 36,
                  "column": 8
                },
                "end": {
                  "line": 38,
                  "column": 8
                }
              },
              "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "day-col/visible-block", [], ["timeStart", ["subexpr", "@mut", [["get", "event.time_start", ["loc", [null, [37, 44], [37, 60]]]]], [], []], "duration", ["subexpr", "@mut", [["get", "event.duration", ["loc", [null, [37, 70], [37, 84]]]]], [], []], "fgColor", ["subexpr", "@mut", [["get", "event.foregroundColor", ["loc", [null, [37, 93], [37, 114]]]]], [], []], "bgColor", ["subexpr", "@mut", [["get", "event.backgroundColor", ["loc", [null, [37, 123], [37, 144]]]]], [], []], "caption", ["subexpr", "@mut", [["get", "event.summary", ["loc", [null, [37, 153], [37, 166]]]]], [], []], "visible", ["subexpr", "@mut", [["get", "event.visible", ["loc", [null, [37, 175], [37, 188]]]]], [], []], "location", ["subexpr", "@mut", [["get", "event.location", ["loc", [null, [37, 198], [37, 212]]]]], [], []]], ["loc", [null, [37, 10], [37, 214]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 6
              },
              "end": {
                "line": 39,
                "column": 6
              }
            },
            "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "event.busy", ["loc", [null, [36, 14], [36, 24]]]]], [], 0, null, ["loc", [null, [36, 8], [38, 15]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 4
            },
            "end": {
              "line": 40,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "event.visible", ["loc", [null, [35, 12], [35, 25]]]]], [], 0, null, ["loc", [null, [35, 6], [39, 13]]]]],
        locals: ["event"],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 4
            },
            "end": {
              "line": 45,
              "column": 4
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "day-col/visible-block", [], ["displayOnTop", true, "timeStart", 32400, "duration", 9000, "fgColor", "#550000", "bgColor", "#FF0000", "caption", "Overlay your calendar", "visible", true, "location", "Click me to see your events on this calendar", "action", "showAuthPopup"], ["loc", [null, [43, 10], [43, 243]]]], ["inline", "day-col/visible-block", [], ["displayOnTop", true, "timeStart", 41400, "duration", 1800, "fgColor", "#550000", "bgColor", "#FF0000", "caption", "", "visible", true, "location", "Dismiss", "action", "dismissBlock"], ["loc", [null, [44, 10], [44, 184]]]]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 26
              },
              "end": {
                "line": 49,
                "column": 92
              }
            },
            "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "availableText");
            var el2 = dom.createTextNode("Available");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "hourCell");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "hour.available", ["loc", [null, [49, 32], [49, 46]]]]], [], 0, null, ["loc", [null, [49, 26], [49, 99]]]]],
        locals: ["hour"],
        templates: [child0]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 52,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "unavailableShadow colCover");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/pods/components/day-col/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "colBody");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "overlay colCover");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "block-house");
        dom.setAttribute(el2, "id", "");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" \n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "colTail");
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [6]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [4]), 1, 1);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 2, 2);
        morphs[4] = dom.createMorphAt(element1, 4, 4);
        morphs[5] = dom.createMorphAt(element1, 5, 5);
        morphs[6] = dom.createMorphAt(element1, 7, 7);
        morphs[7] = dom.createMorphAt(element1, 9, 9);
        morphs[8] = dom.createMorphAt(element0, 8, 8);
        morphs[9] = dom.createMorphAt(element0, 10, 10);
        return morphs;
      },
      statements: [["inline", "cal-mouse-field", [], ["droppedMeetingBlock", ["subexpr", "action", ["placeMeeting"], [], ["loc", [null, [3, 24], [3, 47]]]], "trackMouse", ["subexpr", "action", ["trackMouse"], [], ["loc", [null, [4, 15], [4, 36]]]], "mouseEnter", ["subexpr", "action", ["mouseEnter"], [], ["loc", [null, [5, 15], [5, 36]]]], "mouseLeave", ["subexpr", "action", ["mouseLeave"], [], ["loc", [null, [6, 15], [6, 36]]]], "jumpWeekPrev", ["subexpr", "action", ["jumpWeekPrev"], [], ["loc", [null, [7, 17], [7, 40]]]], "jumpWeekNext", ["subexpr", "action", ["jumpWeekNext"], [], ["loc", [null, [8, 17], [8, 40]]]]], ["loc", [null, [2, 2], [9, 4]]]], ["block", "if", [["get", "day.dateIsToday", ["loc", [null, [13, 8], [13, 23]]]]], [], 0, null, ["loc", [null, [13, 2], [15, 9]]]], ["block", "if", [["get", "busyBlocksVisible", ["loc", [null, [18, 10], [18, 27]]]]], [], 1, null, ["loc", [null, [18, 4], [22, 11]]]], ["block", "each", [["get", "day.unavailableBlocks", ["loc", [null, [23, 12], [23, 33]]]]], [], 2, null, ["loc", [null, [23, 4], [25, 13]]]], ["block", "if", [["get", "hoverVisible", ["loc", [null, [27, 10], [27, 22]]]]], [], 3, null, ["loc", [null, [27, 4], [29, 11]]]], ["block", "if", [["get", "meetingVisible", ["loc", [null, [30, 10], [30, 24]]]]], [], 4, null, ["loc", [null, [30, 4], [32, 11]]]], ["block", "each", [["get", "day.calendarEvents", ["loc", [null, [34, 12], [34, 30]]]]], [], 5, null, ["loc", [null, [34, 4], [40, 13]]]], ["block", "if", [["get", "showConnectCalendarsBlock", ["loc", [null, [42, 10], [42, 35]]]]], [], 6, null, ["loc", [null, [42, 4], [45, 11]]]], ["block", "each", [["get", "day.hours", ["loc", [null, [48, 10], [48, 19]]]]], [], 7, null, ["loc", [null, [48, 2], [50, 11]]]], ["block", "unless", [["get", "day.available", ["loc", [null, [52, 10], [52, 23]]]]], [], 8, null, ["loc", [null, [52, 0], [54, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});