define("foogi-ember-client/templates/components/loading-spin", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/templates/components/loading-spin.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "opacity", ".25");
        dom.setAttribute(el2, "d", "M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animateTransform");
        dom.setAttribute(el3, "attributeName", "transform");
        dom.setAttribute(el3, "type", "rotate");
        dom.setAttribute(el3, "from", "0 16 16");
        dom.setAttribute(el3, "to", "360 16 16");
        dom.setAttribute(el3, "dur", "0.8s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "loadingSvgSize", ["loc", [null, [1, 68], [1, 82]]]]], ["attribute", "height", ["get", "loadingSvgSize", ["loc", [null, [1, 94], [1, 108]]]]], ["attribute", "fill", ["get", "loadingSvgColor", ["loc", [null, [1, 118], [1, 133]]]]]],
      locals: [],
      templates: []
    };
  })());
});