define('foogi-ember-client/pods/request/edit/wizard/who/controller', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed,
      and = computed.and;

  exports['default'] = _ember['default'].Controller.extend({
    pageTitle: _ember['default'].computed('model.meetingOnline', 'model.meetingPhoneCall', function () {
      var online = this.get('model.meetingOnline');
      var phoneCall = this.get('model.meetingPhoneCall');
      var meetingMethod = '';
      if (online) {
        if (phoneCall) {
          meetingMethod = "phone call";
        } else {
          meetingMethod = "online meeting";
        }
      } else {
        meetingMethod = "face to face meeting";
      }
      return 'New ' + meetingMethod;
    }),

    isValid: and('model.validRecipient', 'model.validLocation'),
    whereQuestionTitleText: _ember['default'].computed('model.meetingOnline', function () {
      if (this.get('model.meetingOnline')) {
        return 'How can you be reached?';
      } else {
        return 'Where would you like to meet?';
      }
    })
  });
});