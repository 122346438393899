define('foogi-ember-client/pods/contact/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    //Flags
    iCloud: _emberData['default'].attr('boolean'),
    exchange: _emberData['default'].attr('boolean'),
    o365: _emberData['default'].attr('boolean'),
    yahoo: _emberData['default'].attr('boolean'),
    linkedIn: _emberData['default'].attr('boolean'),
    facebook: _emberData['default'].attr('boolean'),
    other: _emberData['default'].attr('boolean'),

    //Values
    name: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    whichOthers: _emberData['default'].attr('string')

  });
});
//import Ember from 'ember';