define('foogi-ember-client/pods/request/edit/title/route', ['exports', 'ember', 'foogi-ember-client/mixins/request-edit-return-to'], function (exports, _ember, _foogiEmberClientMixinsRequestEditReturnTo) {
  exports['default'] = _ember['default'].Route.extend(_foogiEmberClientMixinsRequestEditReturnTo['default'], {
    actions: {
      resetSummary: function resetSummary() {
        var _this = this;

        var req = this.modelFor(this.routeName);
        req.set('meetingSummaryCustomised', false);
        req.save().then(function () {
          _this.send('saveAndReturn');
        });
      }
    }
  });
});