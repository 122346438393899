define('foogi-ember-client/pods/new/controller', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  var or = computed.or;
  exports['default'] = Controller.extend({
    pageTitle: "New Meeting",
    queryParams: ['meetingSummary', 'receiverDisplayName', 'receiverEmail', 'meetingOutboundMessage'],

    hasDetails: or('meetingSummary', 'receiverDisplayName', 'receiverEmail'),
    receiverLabel: computed('receiverDisplayName', 'receiverEmail', function () {
      var name = this.get('receiverDisplayName');
      var email = this.get('receiverEmail');

      if (name && email) {
        return name + ' (' + email + ')';
      }

      if (name) {
        return name;
      }

      if (email) {
        return email;
      }

      return null;
    })
  });
});