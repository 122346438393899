define('foogi-ember-client/pods/day/model', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  var DayLength = 24 * 60 * 60;

  exports['default'] = _emberData['default'].Model.extend({

    metaCalendar: _emberData['default'].belongsTo('metaCalendar', { async: true }),
    calendarEvents: _emberData['default'].hasMany('calendarEvents'),

    token: _emberData['default'].attr('string'),

    year: _emberData['default'].attr('number'),
    month: _emberData['default'].attr('number'),
    day: _emberData['default'].attr('number'),

    timezone: _emberData['default'].attr('string'),

    availableWindows: _emberData['default'].attr('array'),
    // [ 
    //    { 
    //       "open":61200,
    //       "close":86400
    //    }
    // ],
    calendars: _emberData['default'].attr('array'),
    busyBlocks: _emberData['default'].attr('array'),

    active: true,
    blocksPopulated: true,
    meetings: null, // TODO: Remove
    meetingsNotPopulated: false, // TODO: Remove
    eventsNotPopulated: false, // TODO: Remove
    unavailableBlocks: _ember['default'].computed('availableWindows', function () {
      var blocks = _ember['default'].A();
      var windows = this.get('availableWindows');
      var blockStart = 0;

      if (windows.length === 0) {
        blocks.pushObject({
          start: 0,
          duration: DayLength
        });
        return blocks;
      }

      windows.forEach(function (currWindow) {
        blocks.pushObject({
          start: blockStart,
          duration: currWindow.open - blockStart
        });
        blockStart = currWindow.close;
      });

      // If the window starts at midnight, remove the first zero-length block
      if (blocks.objectAt(0).duration === 0) {
        blocks.shiftObject();
      }

      // Close off the end of the day
      if (blockStart != DayLength) {
        blocks.pushObject({
          start: blockStart,
          duration: DayLength - blockStart
        });
      }

      return blocks;
    }),

    unavailableBlocksPopulated: true, // TODO: Remove

    displayTimezone: _ember['default'].computed.alias('timezone'),
    events: _ember['default'].computed.alias('calendarEvents'),
    dateDoM: _ember['default'].computed.alias('day'),

    available: _ember['default'].computed.not('dateInactive'),

    hours: _ember['default'].computed('date', function () {
      var hours = _ember['default'].A();
      for (var i = 0; i < 24; i++) {
        hours.pushObject({ available: false });
      }
      return hours;
    }),

    date: _ember['default'].computed('year', 'month', 'day', function () {
      return moment.tz({
        y: this.get('year'),
        M: this.get('month') - 1, // From MomentJS docs, months are 0-indexed. because of course they are.
        d: this.get('day')
      }, this.get('timezone'));
    }),

    dayName: _ember['default'].computed('date', function () {
      var date = this.get('date');
      return date.format("ddd");
    }),

    dateIsWeekend: _ember['default'].computed('date', function () {
      var name = this.get('dayName');
      return name === 'Sat' || name === 'Sun';
    }),

    dateInactive: _ember['default'].computed('active', 'date', function () {
      return this.get('dateIsPast') || !this.get('active');
    }),

    dateIsPast: _ember['default'].computed('date', function () {
      var date = moment(this.get('date'));
      return date.isBefore(moment().tz(this.get('displayTimezone')), 'day');
    }),

    dateIsToday: _ember['default'].computed('date', function () {
      var date = moment(this.get('date'));
      return moment().tz(this.get('displayTimezone')).isSame(date, 'day');
    }),

    dayIsClosed: function dayIsClosed() {
      var dateInactive = this.get('dateInactive');
      return dateInactive;
    },

    validTime: function validTime(testTimeStart, testTimeEnd) {
      var _this = this;

      var valid = true;
      var today = this.get('date');
      // Test overlaps with unavailable blocks
      var unavailableBlocks = this.get('unavailableBlocks');
      unavailableBlocks.forEach(function (block) {
        var blockStart = moment(today).add(block.start, 'seconds');
        var blockEnd = moment(blockStart).add(block.duration, 'seconds');
        if (_this.windowOverlapsWindow(testTimeStart, testTimeEnd, blockStart, blockEnd)) {
          valid = false;
        }
      });

      if (valid === false) {
        return false;
      }

      // Test overlaps with busy blocks
      var busyBlocks = this.get('busyBlocks');
      busyBlocks.forEach(function (block) {
        var blockStart = moment(today).add(block.time_start, 'seconds');
        var blockEnd = moment(today).add(block.time_end, 'seconds');
        if (_this.windowOverlapsWindow(testTimeStart, testTimeEnd, blockStart, blockEnd)) {
          valid = false;
        }
      });

      // nothing overlapped! great. return true
      return valid;
    },

    testSetForOverlap: function testSetForOverlap(testArray, testTimeStart, testTimeEnd) {
      var _this2 = this;

      testArray.forEach(function (block) {
        if (_this2.windowOverlapsWindow(testTimeStart, testTimeEnd, block.timeStart, block.timeEnd)) {
          return false;
        }
      });
    },

    windowOverlapsWindow: function windowOverlapsWindow(aStart, aEnd, bStart, bEnd) {
      // A STARTS within B
      if (!aStart.isBefore(bStart) && aStart.isBefore(bEnd)) {
        //using the inverse operations because (!>) is the same as (< || ==)
        return true;
      }

      // A ENDS within B
      if (aEnd.isAfter(bStart) && !aEnd.isAfter(bEnd)) {
        //using the inverse operations because (!>) is the same as (< || ==)
        return true;
      }

      // A Surrounds B
      if (!bStart.isBefore(aStart) && !bEnd.isAfter(aEnd)) {
        //using the inverse operations because (!>) is the same as (< || ==)
        return true;
      }
      return false;
    }

  });
});
/* global moment */