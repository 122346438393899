define('foogi-ember-client/pods/application/route', ['exports', 'ember', 'foogi-ember-client/config/environment', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _ember, _foogiEmberClientConfigEnvironment, _emberSimpleAuthMixinsApplicationRouteMixin) {
  var Route = _ember['default'].Route;
  var inject = _ember['default'].inject;
  var isBlank = _ember['default'].isBlank;
  var $ = _ember['default'].$;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    session: inject.service('session'),
    currentUser: inject.service('session-user'),
    ajax: inject.service(),

    identifyUser: function identifyUser() {
      if (this.get('currentUser.me')) {
        this.segment.identifyUser(this.get('currentUser.me.primaryEmail'), {
          email: this.get('currentUser.me.primaryEmail'),
          display_name: this.get('currentUser.me.displayName'),
          name: this.get('currentUser.me.displayName'),
          uuid: this.get('currentUser.me..id')
        });
      }
    },

    apiKey: function apiKey() {
      return encodeURIComponent(_foogiEmberClientConfigEnvironment['default'].googleApiKey);
    },

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      if (this.get('session.isAuthenticated')) {
        transition.send('checkCurrentUserIsStored');
        transition.send('checkRegistration');
      }
    },

    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
      var controller = this.controllerFor('application');
      controller.set('prevRoute', this.routeName);
    },

    sessionAuthenticated: function sessionAuthenticated() {
      // NO OP

      // EmberSimpleAuth by default will try to retry an intercepted transition or will transition to index.
      // We don't actually want it to try to transition because we can login from the calendar page with an action

      // This empty method is just to override the default behaviour to be a no-op
    },

    actions: {
      willTransition: function willTransition(transition) {
        // jshint ignore:line
        var controller = this.controllerFor('application');
        controller.set('prevURL', this.get('router.url'));
      },

      createRequest: function createRequest(parameters) {
        var _this = this;

        var urlString = _foogiEmberClientConfigEnvironment['default'].API.HOST + '/' + _foogiEmberClientConfigEnvironment['default'].API_NAMESPACE + '/time_requests/new' + '?meeting_online=' + parameters.meetingOnline + '&meeting_phone_call=' + parameters.meetingPhoneCall + '&meeting_summary=' + encodeURIComponent(parameters.meetingSummary) + '&meeting_outbound_message=' + encodeURIComponent(parameters.meetingOutboundMessage) + '&receiver_display_name=' + encodeURIComponent(parameters.receiverDisplayName) + '&receiver_email=' + encodeURIComponent(parameters.receiverEmail);

        if (this.get('session.isAuthenticated')) {
          this.get('session').authorize('authorizer:oauth2', function (headerName, headerValue) {
            _this.get('ajax').request(urlString, {
              method: "GET",
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader(headerName, headerValue);
              }
            }).then(function (newRequest) {
              _this.transitionTo('request.edit.wizard', newRequest.time_requests[0].id);
            });
          });
        }
      },

      showModal: function showModal(name, content) {
        _ember['default'].Logger.debug('showModal');
        this.render(name, {
          into: 'application',
          outlet: 'modal',
          model: content
        });
      },

      removeModal: function removeModal() {
        $('#modal').modal('hide');
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },

      authenticateWithGoogle: function authenticateWithGoogle() {
        var _this2 = this;

        this.get('session').authenticate('simple-auth-authenticator:torii', 'google-oauth2').then(function () {
          _ember['default'].Logger.debug("Success, Auth Code: " + _this2.get('session.authorizationCode'));
        }, function (error) {
          _ember['default'].Logger.error("Failed to authenticate", error);
        });
      },
      googleLogin: function googleLogin() {
        var _this3 = this;

        this.get('session').authenticate('simple-auth-authenticator:torii', 'google-token').then(function () {
          // ajax to backend goes here.
          if (_this3.controllerFor('application').get('prevRoute') === 'login') {
            // TODO use public API
            _this3.transitionTo('index');
          } else {
            _this3.send('backOnePage');
          }
        });
      },
      customAuthlogin: function customAuthlogin(provider) {
        var _this4 = this;

        var toriiProvider = provider + '-oauth2-custom';
        _ember['default'].Logger.debug('Attempting to authenticate using ' + toriiProvider);
        // TODO user feedback here
        this.get('session').authenticate('authenticator:hybrid-oauth2', toriiProvider).then(function () {
          //TODO user feedback here
          _this4.refresh();
        }, function (error) {
          // TODO user feedback here
          _ember['default'].Logger.error("Failed to authenticate", error);
        });
      },
      invalidateSession: function invalidateSession() {
        var session = this.get('session');
        session.set('isInvalidating', true);
        session.set('isWorking', true);
        session.invalidate();
      },
      sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
        // TODO delete?
        // this._super();
        _ember['default'].Logger.debug('authenticationSucceeded');
        this.send('checkRegistration');
        // Check if registration completed otherwise redirect to preferences panel & start meetme page creation.
      },

      invalidationSucceeded: function invalidationSucceeded() {
        var session = this.get('session');
        session.set('isInvalidating', false);
        session.set('isWorking', false);
      },

      checkCurrentUserIsStored: function checkCurrentUserIsStored() {
        // TODO delete?
        _ember['default'].Logger.debug("Checking User Stored");
        if (this.get('session.isAuthenticated') && isBlank(this.get('currentUser.me'))) {
          // var userId = this.get('session.user_id');
          var user = this.store.queryRecord('user', {});
          _ember['default'].Logger.debug("Storing User " + user.id);
          this.set('currentUser.me', user);
        }
      },

      checkRegistration: function checkRegistration() {
        // TODO delete?
        _ember['default'].Logger.debug('checking Registration');
        if (this.get('session.isAuthenticated')) {
          var registrationState = this.get('currentUser.me.registrationStatus');
          this.transitionTo(registrationState);
        }
      },
      setNavContent: function setNavContent(data) {
        this.controllerFor('application').set('navTitleContent', data);
      },
      backOnePage: function backOnePage() {
        var prevTransitionUrl = this.controllerFor('application').get('prevURL');

        if (prevTransitionUrl && prevTransitionUrl !== this.get('router.url')) {
          this.transitionTo(prevTransitionUrl);
        } else {
          this.send('jumpHome');
        }
      },
      jumpHome: function jumpHome() {
        this.transitionTo('index');
      }
    }
  });
});