define('foogi-ember-client/pods/login/register-interest/route', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    model: function model() {
      return this.store.createRecord('contact');
    },

    actions: {
      clickProvider: function clickProvider(provider) {
        this.modelFor('login.register-interest').toggleProperty(provider);
        this.send('showFields');
      },

      showFields: function showFields() {
        _ember['default'].$('#fields').slideDown();
      },

      submit: function submit() {
        var model = this.modelFor('login.register-interest');
        model.save();
        _ember['default'].$('#fields').slideUp();
        _ember['default'].$('#providerButtons').slideUp();
        _ember['default'].$('#thankyou').slideDown();
        //this.set('controller.submitted', true);
      }
    }
  });
});