define('foogi-ember-client/pods/components/wizard-panel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isValid: true, // Set by parent
    isInvalid: _ember['default'].computed.not('isValid'),
    labelPrev: 'Back',
    labelNext: 'Next',
    classNames: ['confirmation-wrapper'],
    showNext: true,
    actions: {
      close: function close() {
        this.sendAction('close');
      },
      next: function next() {
        this.sendAction('next');
      },
      prev: function prev() {
        this.sendAction('prev');
      }
    }
  });
});