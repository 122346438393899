define('foogi-ember-client/pods/profile/edit/controller', ['exports', 'ember'], function (exports, _ember) {
  var Controller = _ember['default'].Controller;
  var inject = _ember['default'].inject;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    currentUser: inject.service('session-user'),

    pageTitle: 'Meeting profile',
    isValid: true,
    firstRun: computed('currentUser.me.defaultProfileCompleted', function () {
      var defaultProfileCompleted = this.get('currentUser.me.defaultProfileCompleted');
      return !defaultProfileCompleted;
    }),
    saveButtonText: computed('firstRun', function () {
      var firstRun = this.get('firstRun');
      return firstRun ? 'Done' : 'Save';
    })
  });
});