define('foogi-ember-client/mixins/wizard-progress', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    readyToGo: function readyToGo() {
      return this.progress() === 'request.confirm'; //TODO this is double handling. could do with a refactor
    },
    // WORK OUT WHICH STEP IS FURTHEST THE USER CAN JUMP TO
    progress: function progress() {
      var req = this.modelFor('request');
      var routePrefix = 'request.edit.wizard.';

      // who
      if (!req.get('validRecipient')) {
        return routePrefix + 'who';
      }
      // availability time
      if (!req.get('optionsSelectedAvailability')) {
        return routePrefix + 'availability';
      }

      if (!req.get('validTime')) {
        return 'request.edit.time';
      }
      // summary
      return 'request.confirm';
    }
  });
});