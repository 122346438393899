define('foogi-ember-client/pods/components/day-col/visible-block/component', ['exports', 'ember', 'foogi-ember-client/constants'], function (exports, _ember, _foogiEmberClientConstants) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var HourHeight = _foogiEmberClientConstants['default'].HourHeight;
  var SnapMinutes = _foogiEmberClientConstants['default'].SnapMinutes;
  var MinutesInHour = _foogiEmberClientConstants['default'].MinutesInHour;
  var SecondsInHour = _foogiEmberClientConstants['default'].SecondsInHour;

  var Transparency = 0.4;
  var MaxBlockWidth = '100%';

  exports['default'] = Component.extend({
    timeStart: null,
    duration: null,

    busyBlock: false,

    bgColor: '',
    fgColor: '',
    transparent: true,

    simultaneousBlocks: 1,
    simultaneousBlockNumber: 0,

    caption: '',

    displayOnTop: false,

    // attributeBindings: ['height', 'top'],
    top: computed('timeStart', function () {
      var timeStart = this.get('timeStart');
      return parseInt(timeStart / SecondsInHour * HourHeight);
    }),

    height: computed('duration', function () {
      var duration = this.get('duration');
      return parseInt(duration / SecondsInHour * HourHeight);
    }),

    // hasColor: computed('bgColor', 'fgColor', function() {
    //   return isPresent('bgColor') && isPresent('fgColor');
    // }),

    // isOffset: computed('simultaneousBlocks', 'simultaneousBlockNumber', function() {
    //   var blocks = this.get('simultaneousBlocks');
    //   return (blocks != 1);
    // }),

    opacity: computed('transparent', function () {
      if (this.get('transparent') === true) {
        return Transparency;
      } else {
        return 1;
      }
    }),

    width: computed('simultaneousBlocks', 'simultaneousBlockNumber', function () {
      return MaxBlockWidth;
    }),

    left: computed('simultaneousBlocks', 'simultaneousBlockNumber', function () {
      return '0';
    }),

    busyBlockStyle: computed('height', 'top', 'left', function () {
      var height = this.get('height');
      var left = this.get('left');
      var top = this.get('top');
      return new _ember['default'].String.htmlSafe("height: " + height + "px; top: " + top + "px; left: " + left + "px");
    }),

    eventBlockStyle: computed('height', 'top', 'left', 'bgColor', function () {
      var height = this.get('height');
      var left = this.get('left');
      var top = this.get('top');
      var bgColor = this.get('bgColor');
      return new _ember['default'].String.htmlSafe("height: " + height + "px; top: " + top + "px; left: " + left + "px; border-left: 3px solid " + bgColor);
    }),

    actions: {
      eventClick: function eventClick() {
        this.sendAction();
      }
    }
  });
});
/* global moment */