define("foogi-ember-client/pods/components/dashboard-card/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("em");
          var el3 = dom.createTextNode("(");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(")");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element10, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["time ", ["get", "iconStatus", ["loc", [null, [9, 21], [9, 31]]]]]]], ["content", "textMeetingTimeDetailsReceiver", ["loc", [null, [9, 64], [9, 98]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 14,
                "column": 4
              }
            },
            "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-phone fa-fw");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Call: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            morphs[1] = dom.createMorphAt(element9, 2, 2);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["contact ", ["get", "iconStatus", ["loc", [null, [13, 24], [13, 34]]]]]]], ["content", "textContactDetails", ["loc", [null, [13, 77], [13, 99]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 16,
                "column": 4
              }
            },
            "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-video-camera fa-fw");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            morphs[1] = dom.createMorphAt(element8, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["contact ", ["get", "iconStatus", ["loc", [null, [15, 24], [15, 34]]]]]]], ["content", "textContactDetails", ["loc", [null, [15, 78], [15, 100]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "request.meetingPhoneCall", ["loc", [null, [12, 10], [12, 34]]]]], [], 0, 1, ["loc", [null, [12, 4], [16, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 2
            },
            "end": {
              "line": 19,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-map-marker fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element7, 'class');
          morphs[1] = dom.createMorphAt(element7, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["location ", ["get", "iconStatus", ["loc", [null, [18, 23], [18, 33]]]]]]], ["content", "textLocation", ["loc", [null, [18, 75], [18, 91]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 2
            },
            "end": {
              "line": 25,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-dashboard-card-action edit");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-pencil fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Edit");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-dashboard-card-action delete");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-trash-o fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Delete Draft");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element5);
          morphs[1] = dom.createElementMorph(element6);
          return morphs;
        },
        statements: [["element", "action", ["editClick"], [], ["loc", [null, [23, 55], [23, 77]]]], ["element", "action", ["cancelClick"], [], ["loc", [null, [24, 57], [24, 81]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 2
            },
            "end": {
              "line": 29,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-dashboard-card-action resend");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-paper-plane fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Resend");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn btn-dashboard-card-action cancel");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-ban fa-fw");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Cancel Invitation");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(fragment, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element3);
          morphs[1] = dom.createElementMorph(element4);
          return morphs;
        },
        statements: [["element", "action", ["resendClick"], [], ["loc", [null, [27, 57], [27, 81]]]], ["element", "action", ["cancelClick"], [], ["loc", [null, [28, 57], [28, 81]]]]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-dashboard-card-action hide-card");
            dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-minus fa-fw");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Hide");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["element", "action", ["hideClick"], [], ["loc", [null, [32, 62], [32, 84]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.6.2",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 4
              },
              "end": {
                "line": 36,
                "column": 4
              }
            },
            "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-dashboard-card-action cancel-delete");
            dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-ban fa-fw");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Cancel meeting & Delete");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "btn btn-dashboard-card-action hide-card");
            dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-minus fa-fw");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Hide");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["cancelClick"], [], ["loc", [null, [34, 66], [34, 90]]]], ["element", "action", ["hideClick"], [], ["loc", [null, [35, 62], [35, 84]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.6.2",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 2
            },
            "end": {
              "line": 37,
              "column": 2
            }
          },
          "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "cardIsExpired", ["loc", [null, [31, 10], [31, 23]]]]], [], 0, 1, ["loc", [null, [31, 4], [36, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/pods/components/dashboard-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("i");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "fa fa-clock-o fa-fw");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "buttons");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [0]);
        var element12 = dom.childAt(fragment, [2]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element14, [0]);
        var element16 = dom.childAt(element12, [5]);
        var element17 = dom.childAt(fragment, [4]);
        var morphs = new Array(17);
        morphs[0] = dom.createAttrMorph(element11, 'class');
        morphs[1] = dom.createElementMorph(element11);
        morphs[2] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
        morphs[3] = dom.createAttrMorph(element12, 'class');
        morphs[4] = dom.createElementMorph(element12);
        morphs[5] = dom.createAttrMorph(element13, 'class');
        morphs[6] = dom.createMorphAt(element13, 0, 0);
        morphs[7] = dom.createAttrMorph(element14, 'class');
        morphs[8] = dom.createAttrMorph(element15, 'class');
        morphs[9] = dom.createMorphAt(element14, 1, 1);
        morphs[10] = dom.createAttrMorph(element16, 'class');
        morphs[11] = dom.createMorphAt(element16, 1, 1);
        morphs[12] = dom.createMorphAt(element12, 7, 7);
        morphs[13] = dom.createMorphAt(element12, 8, 8);
        morphs[14] = dom.createMorphAt(element17, 1, 1);
        morphs[15] = dom.createMorphAt(element17, 2, 2);
        morphs[16] = dom.createMorphAt(element17, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["card-top-bar ", ["get", "iconStatus", ["loc", [null, [1, 27], [1, 37]]]]]]], ["element", "action", ["cardClick"], [], ["loc", [null, [1, 41], [1, 63]]]], ["content", "textMeetingSummary", ["loc", [null, [2, 5], [2, 27]]]], ["attribute", "class", ["concat", ["card-body ", ["get", "iconStatus", ["loc", [null, [4, 24], [4, 34]]]]]]], ["element", "action", ["cardClick"], [], ["loc", [null, [4, 38], [4, 60]]]], ["attribute", "class", ["concat", ["status ", ["get", "iconStatus", ["loc", [null, [5, 21], [5, 31]]]]]]], ["content", "textMeetingStatus", ["loc", [null, [5, 35], [5, 56]]]], ["attribute", "class", ["concat", ["invitee ", ["get", "iconStatus", ["loc", [null, [6, 22], [6, 32]]]]]]], ["attribute", "class", ["concat", ["fa ", ["get", "textIconInvitee", ["loc", [null, [6, 51], [6, 66]]]], " fa-fw ", ["get", "iconStatus", ["loc", [null, [6, 77], [6, 87]]]]]]], ["content", "textRecipientDisplayName", ["loc", [null, [6, 95], [6, 123]]]], ["attribute", "class", ["concat", ["time ", ["get", "iconStatus", ["loc", [null, [7, 19], [7, 29]]]]]]], ["content", "textMeetingTimeDetails", ["loc", [null, [7, 68], [7, 94]]]], ["block", "if", [["get", "timezoneDifferent", ["loc", [null, [8, 8], [8, 25]]]]], [], 0, null, ["loc", [null, [8, 2], [10, 9]]]], ["block", "if", [["get", "request.meetingOnline", ["loc", [null, [11, 8], [11, 29]]]]], [], 1, 2, ["loc", [null, [11, 2], [19, 9]]]], ["block", "if", [["get", "cardIsDraft", ["loc", [null, [22, 8], [22, 19]]]]], [], 3, null, ["loc", [null, [22, 2], [25, 9]]]], ["block", "if", [["get", "cardIsWaiting", ["loc", [null, [26, 8], [26, 21]]]]], [], 4, null, ["loc", [null, [26, 2], [29, 9]]]], ["block", "if", [["get", "cardIsResponded", ["loc", [null, [30, 8], [30, 23]]]]], [], 5, null, ["loc", [null, [30, 2], [37, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});