define('foogi-ember-client/pods/request/edit/wizard/availability/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    pageTitle: 'Select the meeting window',

    isValid: true,

    timeImage: _ember['default'].computed('model.availabilityTimeMode', function () {
      var time = this.get('model.availabilityTimeMode');
      var img = "";
      switch (time) {
        case 'working':
          img = 'availability-cal-working.png';
          break;
        case 'waking':
          img = 'availability-cal-waking.png';
          break;
        case 'all':
          img = 'availability-cal-anytime.png';
          break;
      }
      var baseUrl = 'assets/images/request/wizard/';
      return this.assets.resolve(baseUrl + img);
    }),
    rangeImage: _ember['default'].computed('model.availabilityRangeMode', function () {
      var range = this.get('model.availabilityRangeMode');
      var img = "";
      switch (range) {
        case 'week':
          img = 'when-cal-1week.png';
          break;
        case 'month':
          img = 'when-cal-1month.png';
          break;
        case 'any':
          img = 'when-cal-anytime.png';
          break;
      }
      var baseUrl = 'assets/images/request/wizard/';
      return this.assets.resolve(baseUrl + img);
    })

  });
});