define('foogi-ember-client/pods/components/get-started/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['get-started-wrapper', 'panel', 'wizard-panel'],
    profile: null,

    actions: {
      pressedEnterKey: function pressedEnterKey() {
        this.sendAction("pressedEnterKey");
      },
      setMeetingLocation: function setMeetingLocation(place) {
        this.sendAction('setMeetingLocation', place);
      },
      clearLocation: function clearLocation() {
        this.sendAction('clearLocation');
      },
      saveProfile: function saveProfile() {
        this.sendAction('saveProfile');
      }
    }
  });
});