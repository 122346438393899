define('foogi-ember-client/pods/request/edit/location/controller', ['exports', 'ember', 'foogi-ember-client/mixins/request-user-session'], function (exports, _ember, _foogiEmberClientMixinsRequestUserSession) {
  exports['default'] = _ember['default'].Controller.extend(_foogiEmberClientMixinsRequestUserSession['default'], {
    request: _ember['default'].computed.alias('model'),

    pageTitle: 'Where will the meeting take place?',
    isInvalid: _ember['default'].computed.not('model.validLocation'),
    whereQuestionTitleText: _ember['default'].computed('model.meetingOnline', function () {
      if (this.get('model.meetingOnline')) {
        return 'How can you be reached?';
      } else {
        return 'Where would you like to meet?';
      }
    })
  });
});