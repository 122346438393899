define('foogi-ember-client/pods/array/transform', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return _ember['default'].A(serialized);
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});