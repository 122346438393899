define('foogi-ember-client/pods/time-request/model', ['exports', 'ember-data', 'ember', 'foogi-ember-client/pods/hash/transform'], function (exports, _emberData, _ember, _foogiEmberClientPodsHashTransform) {
  // jshint ignore:line

  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var isBlank = _ember['default'].isBlank;
  var alias = computed.alias;
  var deprecatingAlias = computed.deprecatingAlias;
  var not = computed.not;
  var notEmpty = computed.notEmpty;
  var equal = computed.equal;
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    _blank: function _blank(param) {
      return isBlank(this.get(param));
    },

    // Methods
    testContactValidity: function testContactValidity(condition, value) {
      return condition ? isPresent(value) : true; // If we're set to use a contact option, make sure it has a value
    },
    // Validations
    validations: {
      hostName: { presence: true },
      meetingDuration: { presence: true, numericality: true }
    },

    // Associations
    user: _emberData['default'].belongsTo('user', { async: true }),
    metaCalendar: _emberData['default'].belongsTo('metaCalendar', { async: true }),
    busyBlocks: _emberData['default'].hasMany('busy-block'),
    mutableBusyBlocks: computed('busyBlocks.[]', 'displayTimezone', function () {
      return _ember['default'].copy(_ember['default'].A().addObjects(this.get('busyBlocks')));
    }),

    // API-backed attributes
    token: attr('string'),
    hostName: attr('string'),
    receiverDisplayName: attr('string'),
    receiverEmail: attr('string'),
    meetingOnline: attr('boolean'),
    meetingPhoneCall: attr('boolean'),
    meetingSummary: attr('string'),
    meetingSummaryCustomised: attr('boolean'),
    meetingDescription: attr('string'),
    meetingDuration: attr('number'),

    meetingTimezone: deprecatingAlias('timezoneSender'),
    displayTimezone: alias('meetingTimezone'), //TODO This is a mad hack to get the display to play nice

    timezoneSender: attr('string', { readOnly: true }),
    timezoneReceiver: attr('string', { readOnly: true }),

    receiverContactDetails: attr('string'),
    senderContactDetails: attr('string'),

    meetingTime: attr('utc'),
    meetingNotes: attr('string'),
    locName: attr('string'),
    locPlaceId: attr('string'),
    locLat: attr('string'),
    locLng: attr('string'),
    locAddress: attr('string'),
    meetingOutboundMessage: attr('string'),
    state: attr('string', { readOnly: true }),
    statusDraft: attr('boolean', { defaultValue: true }),
    statusResponded: attr('boolean', { readOnly: true }),
    emailInviteSent: attr('boolean', { readOnly: true }),
    emailInviteSentCount: attr('number', { readOnly: true }),
    emailBriefingSent: attr('boolean', { readOnly: true }),
    emailFollowupSent: attr('boolean', { readOnly: true }),
    createdAt: attr('utc', { readOnly: true }),
    updatedAt: attr('utc', { readOnly: true }),

    //Temporary API-backed attributes to get this shit out the door
    availabilityRangeMode: attr('string'),
    availabilityTimeMode: attr('string'),

    availabilityRangeStart: attr('utc'),
    availabilityRangeEnd: attr('utc'),
    dateStart: alias('availabilityRangeStart'),
    dateEnd: alias('availabilityRangeEnd'),

    dateRange: computed('dateStart', 'dateEnd', function () {
      return _ember['default'].Object.create({
        dateStart: moment(this.get('dateStart')),
        dateEnd: moment(this.get('dateEnd'))
      });
    }),
    optionsSelectedAvailability: attr('boolean'),

    // Aliases for convenience
    receiverName: deprecatingAlias('receiverDisplayName'),
    meetingInPerson: not('meetingOnline'),
    meetingStart: deprecatingAlias('meetingTime'), // Working clone of meetingTime used to initially set meeting block state.
    meetingLocationName: alias('googlePlaceObject.name'),
    meetingLocationLat: alias('googlePlaceObject.geometry.location.k'),
    meetingLocationLng: alias('googlePlaceObject.geometry.location.D'),
    locationText: alias('locName'), //TODO make this render the location properly based on online/offline

    // Ephemeral, view-related properties that need a refactor
    googlePlaceObject: attr('hash'),
    meetingBlockFloating: true,

    // Some aliases to tide us over until the googlePlaceObject is handled better
    mapMarker: computed('googlePlaceObject', 'markerForGooglePlaceObject', 'markerForStoredLocation', function () {
      return isPresent(this.get('googlePlaceObject')) ? this.get('markerForGooglePlaceObject') : this.get('markerForStoredLocation');
    }),
    mapLat: computed('googlePlaceObject', 'meetingLocationLat', 'locLat', function () {
      return isPresent(this.get('googlePlaceObject')) ? this.get('meetingLocationLat') : this.get('locLat');
    }),
    mapLng: computed('googlePlaceObject', 'meetingLocationLng', 'locLng', function () {
      return isPresent(this.get('googlePlaceObject')) ? this.get('meetingLocationLng') : this.get('locLng');
    }),

    // Validation properties
    validRecipient: computed('receiverEmail', 'receiverDisplayName', 'meetingOnline', 'validReceiverTimezone', function () {
      var validRecipientDetails = isPresent(this.get('receiverEmail')) && //TODO Email validation
      isPresent(this.get('receiverDisplayName'));
      if (this.get('meetingOnline')) {
        return validRecipientDetails && this.get('validReceiverTimezone');
      } else {
        return validRecipientDetails;
      }
    }),

    validLocation: notEmpty('locPlaceId'), // TODO: make this validation real
    validReceiverTimezone: notEmpty('locName'), // TODO: make this validation real
    validSummary: notEmpty('meetingSummary'), // TODO: make this validation real
    validAvailabilityRange: notEmpty('availabilityRangeMode'), // Used in progress mixin
    validAvailabilityTime: notEmpty('availabilityTimeMode'), // Used in progress mixin
    validOutboundMessage: notEmpty('meetingOutboundMessage'),
    validSenderContactDetails: notEmpty('senderContactDetails'),
    validReceiverContactDetails: notEmpty('receiverContactDetails'),
    validTime: computed('meetingTime', {
      get: function get() {
        return moment(this.get('meetingTime')).isAfter();
        // TODO: check if it's also during our range and availability
      }
    }),

    // Computed properties
    //meetingStartInTz: computed('meetingStart') //TODO: make computed properties work of these 'in tz' properties
    meetingEnd: computed('meetingTime', 'meetingDuration', {
      get: function get() {
        return this.get('meetingTime') ? moment(this.get('meetingTime')).add(this.get('meetingDuration'), 'minutes') : null;
      }
    }),
    locLngInteger: computed('locLng', function () {
      return parseFloat(this.get('locLng'));
    }),
    locLatInteger: computed('locLat', function () {
      return parseFloat(this.get('locLat'));
    }),
    markerForGooglePlaceObject: computed('googlePlaceObject', function () {
      return [{
        title: this.get('meetingLocationName'),
        lat: this.get('googlePlaceObject.geometry.location.k'),
        lng: this.get('googlePlaceObject.geometry.location.D'),
        address: this.get('googlePlaceObject.adr_address'),
        phoneNumber: this.get('googlePlaceObject.formatted_phone_number'),
        website: this.get('googlePlaceObject.website'),
        googlePlus: this.get('googlePlaceObject.url'),
        isDraggable: false
      }];
    }),
    markerForStoredLocation: computed(function () {
      return [{
        title: this.get('locName'),
        lat: this.get('locLatInteger'),
        lng: this.get('locLngInteger'),
        address: this.get('locAddress')
      }];
    }),

    displayConfirmationDay: computed('meetingStart.unix', {
      get: function get() {
        if (!this.get('meetingStart')) {
          return null;
        }
        return moment(this.get('meetingStart')).format('dddd, D MMMM YYYY');
      }
    }),
    displayConfirmationTimes: computed('meetingStart.unix', 'meetingEnd', 'meetingDuration', {
      get: function get() {
        if (!this.get('meetingStart') || !this.get('meetingEnd')) {
          return null;
        }
        return moment(this.get('meetingStart')).format('h:mmA') + ' - ' + moment(this.get('meetingEnd')).format('h:mmA');
      }
    }),
    differentTimezones: computed('displayTimezone', 'meetingTimezone', {
      get: function get() {
        if (!this.get('displayTimezone') || !this.get('meetingTimezone')) {
          return null;
        }
        return this.get('displayTimezone') !== this.get('meetingTimezone');
      }
    }),

    senderFormattedDay: computed('meetingStart.unix', 'meetingTimezone', {
      get: function get() {
        if (!this.get('meetingStart') || !this.get('meetingTimezone')) {
          return null;
        }
        return moment(this.get('meetingStart')).tz(this.get('meetingTimezone')).format('ddd, D MMMM YYYY');
      }
    }),
    senderFormattedStartEnd: computed('meetingStart.unix', 'timezoneSender', 'meetingDuration', function () {
      if (!this.get('meetingStart') || !this.get('timezoneSender')) {
        return null;
      }
      var timeStart = moment(this.get('meetingStart')).tz(this.get('timezoneSender'));
      var timeEnd = moment(this.get('meetingEnd')).tz(this.get('timezoneSender'));

      return timeStart.twix(timeEnd).format({ showDate: false });
    }),

    receiverFormattedDay: computed('meetingStart.unix', 'timezoneReceiver', function () {
      if (!this.get('meetingStart') || !this.get('timezoneReceiver')) {
        return null;
      }
      return moment(this.get('meetingStart')).tz(this.get('timezoneReceiver')).format('ddd, D MMMM YYYY');
    }),
    receiverFormattedStartEnd: computed('meetingStart.unix', 'timezoneReceiver', 'meetingDuration', function () {
      if (!this.get('meetingStart') || !this.get('timezoneReceiver')) {
        return null;
      }
      var timeStart = moment(this.get('meetingStart')).tz(this.get('timezoneReceiver'));
      var timeEnd = moment(this.get('meetingEnd')).tz(this.get('timezoneReceiver'));

      return timeStart.twix(timeEnd).format({ showDate: false });
    }),

    meetingDurationString: computed('meetingTime', 'meetingEnd', { // Used in dashboard-card
      get: function get() {
        return moment.preciseDiff(this.get('meetingTime'), this.get('meetingEnd'));
      }
    }),
    senderReceiverTzSame: computed('timezoneSender', 'timezoneReceiver', function () {
      return this.get('timezoneSender') === this.get('timezoneReceiver');
    }),
    senderReceiverTzDifferent: not('senderReceiverTzSame'),
    meetingTimezoneString: computed('meetingTimezone', {
      get: function get() {
        var meetingStart = this.get('meetingStart');
        var meetingTimezone = this.get('meetingTimezone');
        if (isBlank(meetingStart)) {
          return "";
        }
        if (isBlank(meetingTimezone)) {
          return moment(meetingStart).format('z');
        }
        return moment(meetingStart).tz(meetingTimezone).format('z');
      }
    }) //Used in dashboard-card

  });
});
/* global moment */