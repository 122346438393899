define('foogi-ember-client/helpers/duration-human', ['exports', 'ember'], function (exports, _ember) {
  exports.durationHuman = durationHuman;

  //input in minutes

  function durationHuman(input) {

    if (input === 0) {
      return "0 minutes ";
    }

    var mins = input % 60;
    var hours = Math.floor(input / 60 % 24);
    var days = Math.floor(input / 60 / 24);

    var stringHours = '';
    var stringMinutes = '';
    var stringDays = '';

    // TODO tidy this up. there are better helpers to do this

    if (days > 0) {
      stringDays = days === 1 ? days.toString() + ' day ' : days.toString() + ' days ';
    }

    if (hours > 0) {
      stringHours = hours === 1 ? hours.toString() + ' hour ' : hours.toString() + ' hours ';
    }

    if (mins > 0) {
      stringMinutes = mins === 1 ? mins.toString() + ' minute ' : mins.toString() + ' minutes ';
    }

    return stringDays + stringHours + stringMinutes;
  }

  exports['default'] = _ember['default'].Helper.helper(durationHuman);
});