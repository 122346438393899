define('foogi-ember-client/pods/components/sidebar-card-calendar-options/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['foogi-nav-container'],
    user: null,
    notifications: false,
    displayTimezone: null,
    requestTimezone: null,
    meetingEnd: null,
    hostName: null,
    receiverDisplayName: null,
    placedTimeCoOrds: null,
    activeWeek: null,
    calendarOptionsOpen: false,
    authenticated: false,

    actions: {
      toggleCalendar: function toggleCalendar(calendar, value) {
        this.sendAction('toggleCalendar', calendar, value);
        // this.sendAction('toggleCalendar', account, calendar, value);
      },
      authenticate: function authenticate() {
        this.sendAction('authenticate');
      },
      slideCalendarPanel: function slideCalendarPanel() {
        $('#calendar-options-slide-panel').slideToggle();
        this.toggleProperty('calendarOptionsOpen');
      },
      updateDuration: function updateDuration(value) {
        this.sendAction('updateDuration', value);
      }
    }

  });
});
/* global moment */
/* global jstz */