define('foogi-ember-client/pods/application/adapter', ['exports', 'foogi-ember-client/config/environment', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _foogiEmberClientConfigEnvironment, _activeModelAdapter, _emberSimpleAuthMixinsDataAdapterMixin) {

  var adapter;

  if (_foogiEmberClientConfigEnvironment['default'].ENABLE_MOCK === 'true') {
    adapter = _activeModelAdapter['default'].extend({
      namespace: _foogiEmberClientConfigEnvironment['default'].API_NAMESPACE
    });
  } else {
    adapter = _activeModelAdapter['default'].extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
      authorizer: 'authorizer:oauth2',
      coalesceFindRequests: true,
      host: _foogiEmberClientConfigEnvironment['default'].API.HOST,
      namespace: _foogiEmberClientConfigEnvironment['default'].API_NAMESPACE
    });
  }
  exports['default'] = adapter;
});