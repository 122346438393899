define('foogi-ember-client/router', ['exports', 'ember', 'foogi-ember-client/config/environment'], function (exports, _ember, _foogiEmberClientConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _foogiEmberClientConfigEnvironment['default'].locationType,

    trackPageView: (function () {
      var applicationController = this.container.lookup("controller:application");
      var currentRouteName = applicationController.get("currentRouteName");
      applicationController.segment.trackPageView(currentRouteName);
    }).on('didTransition')
  });

  Router.map(function () {
    this.route('login', function () {
      this.route('register-interest');
    });
    //this.route('preferences');

    this.route('profile', {
      path: '/profile/:id' // logic redirect to edit. {{outlet}}
    }, function () {
      this.route('edit');
    });

    this.route('meetme');

    this.route('meetme.new', {
      path: '/meetme/new'
    });

    this.route('meetme.show', {
      path: '/meetme/:meetme_vanity_url'
    });

    //this.route('confirmRequest', {
    //  path: '/request/:token/confirm'
    //});

    // Unused
    //this.route('new', {
    //  path: '/request/new'
    //});

    //this.route('who', {
    //  path: '/request/new/who'
    //});
    //
    //this.route('timezone', {
    //  path: '/request/new/timezone'
    //});
    //
    //this.route('confirm', {
    //  path: '/request/new/confirm'
    //});

    this.route('user.show', {
      path: '/:userName'
    });

    //this.route('edit', {
    //  path: 'request/:token/edit'
    //}, function() {
    //  this.route('summary');
    //});

    // this.route('time-request', function() {});

    // this.route('request', {
    //   path: '/request/:token'
    // }, function() {
    //   this.route('show');
    //   this.route('edit');
    // });

    /*
     request - overviews and display of a request from either olli or indi's perspective
     can call only two actions - send req (olli) or confirm changes (indi)
      edit - modifying individual components of the request
     */

    this.route('new');

    this.route('request', {
      path: '/request/:token' // logic redirect to edit. {{outlet}}
    }, function () {
      this.route('edit', function () {
        // finds out where to send olli, sends indi back {{outlet}}
        this.route('wizard', function () {
          // Olli's build wizard
          this.route('what'); //1. What is your meeting about? (Title/short description)
          this.route('how'); //2. Is this meeting *In Person* OR *Online*? (meeting type)
          this.route('who'); //3. Who are you trying to meet? (Contact/invitee)
          this.route('timezone'); //3b. Where are they located? (Timezone for online track)
          //this.route('power'); //4. Are *they* being chased by you OR are *they* chasing you? (Power - Mouse or Cat)
          this.route('where'); //5a. Where would you like to meet? (Location for In person track)
          this.route('contact'); //5b. How would you like to be contacted? (Skype, Hangout, Phone call etc. - for Online track)
          this.route('duration'); //6. How long should the meeting be? (duration)
          this.route('range'); //7. When would you like to meet? (booking window/date range)
          this.route('availability'); //8. What availability would you like to share? (availability/time filter)
          this.route('time'); //9. pick a time
        });
        // Common edit routes
        this.route('availability'); //8. What availability would you like to share? (availability/time filter)
        this.route('time'); // Calendar Display
        this.route('location');
        this.route('title');
        this.route('invitee');
        this.route('timezone');
        this.route('detected-timezone');
        this.route('deprecated'); // TODO delete this route when we have sucked out all of its juicy goodness
      });
      this.route('confirm'); // Last thing Olli sees, first thing indi sees. boom.
      this.route('outbound');
      this.route('complete');
      this.route('show');
    });
  });

  exports['default'] = Router;
});