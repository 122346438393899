define('foogi-ember-client/torii-providers/outlook-oauth2-custom', ['exports', 'torii/providers/oauth2-code', 'torii/configuration'], function (exports, _toriiProvidersOauth2Code, _toriiConfiguration) {
  exports['default'] = _toriiProvidersOauth2Code['default'].extend({

    name: 'outlook-oauth2-custom',
    baseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',

    // additional params that this provider requires
    // requiredUrlParams: ['scope'],
    optionalUrlParams: ['scope', 'request_visible_actions', 'access_type', 'approval_prompt'],

    // requestVisibleActions: configurable('requestVisibleActions', ''),

    // approvalPrompt: configurable('approvalPrompt', 'force'),

    // accessType: configurable('accessType', ''),

    // state: configurable('state', 'STATE'),
    responseParams: ['code', 'session_state', 'state'],
    responseType: (0, _toriiConfiguration.configurable)('response_type', 'code'),

    scope: (0, _toriiConfiguration.configurable)('scope', 'email'),
    redirectUri: (0, _toriiConfiguration.configurable)('redirectUri')

  });
});
/**
 * This class implements authentication against google
 * using the OAuth2 authorization flow in a popup window.
 */