define('foogi-ember-client/pods/components/dashboard-card/component', ['exports', 'ember'], function (exports, _ember) {

  var computed = _ember['default'].computed,
      alias = computed.alias,

  //eq = computed.equal,
  not = computed.not,
      or = computed.or;

  exports['default'] = _ember['default'].Component.extend({
    request: {},
    classNames: 'dashboard-card',
    stateMappings: {
      draft: 'draft',
      email_sending: 'sending email',
      email_delivered: 'email sent',
      email_undeliverable: 'email undeliverable due to error.',
      email_opened: 'email opened',
      email_link_clicked: 'email clicked',
      looking_for_better_time: 'email clicked',
      accepted: 'accepted',
      accepted_with_changes: 'accepted',
      declined: 'declined',
      failed_internal: 'internal error. contact support!'
    },
    iconMappings: {
      draft: 'fa-user',
      email_sending: 'fa-question',
      email_delivered: 'fa-question',
      email_undeliverable: 'fa-exclamation',
      email_opened: 'fa-question',
      email_link_clicked: 'fa-question',
      looking_for_better_time: 'fa-question',
      accepted: 'fa-check-circle-o',
      accepted_with_changes: 'fa-check-circle-o',
      declined: 'fa-times-circle-o',
      failed_internal: 'fa-exclamation'
    },
    textRecipientDisplayName: computed('request.receiverDisplayName', {
      get: function get() {
        var name = this.get('request.receiverDisplayName');
        return _ember['default'].isBlank(name) ? '—' : name;
      }
    }),
    textContactDetails: computed('request.senderContactDetails', {
      get: function get() {
        var name = this.get('request.senderContactDetails');
        return _ember['default'].isBlank(name) ? '—' : name;
      }
    }),
    textIconInvitee: computed('request.state', {
      get: function get() {
        var state = this.get('request.state');
        var mappings = this.get('iconMappings');
        return _ember['default'].isEmpty(state) ? 'fa-exclamation' : mappings[state];
      }
    }),
    iconStatus: alias('request.state'),
    textMeetingStatus: computed('request.state', {
      get: function get() {
        var state = this.get('request.state');
        var mappings = this.get('stateMappings');
        return _ember['default'].isEmpty(state) ? 'New Meeting' : mappings[state];
      }
    }),
    textMeetingSummary: computed('request.meetingSummary', {
      get: function get() {
        var summary = this.get('request.meetingSummary');
        return _ember['default'].isEmpty(summary) ? '"—"' : '"' + summary + '"';
      }
    }),
    textMeetingTimeDetails: computed('request.meetingStart', 'request.timezoneSender', 'clock.hour', function () {
      var tz = this.get('request.timezoneSender');
      return this.formatTimeText(tz);
    }),
    textMeetingTimeDetailsReceiver: computed('request.meetingStart', 'request.timezoneReceiver', 'clock.hour', function () {
      var tz = this.get('request.timezoneReceiver');
      return this.formatTimeText(tz);
    }),
    localTimeZone: computed('clock.hour', function () {
      return jstz.determine();
    }),
    now: computed('clock.minute', function () {
      return moment();
    }),
    timezoneDifferent: computed('request.timezoneReceiver', 'request.timezoneSender', function () {
      var timezoneReceiver = this.get('request.timezoneReceiver');
      var timezoneSender = this.get('request.timezoneSender');
      return timezoneSender != timezoneReceiver;
    }),
    showTimeZone: computed('request.meetingStart', 'request.timezoneSender', 'request.meetingOnline', 'localTimeZone', function () {
      var tzSender = this.get('request.timezoneSender');
      var localTimeZone = this.get('localTimeZone').name();
      var online = this.get('request.meetingOnline');
      return online || tzSender != localTimeZone; // true if 'online' or local tz doesn't match sender tz
    }),
    meetingTimeSet: computed('request.meetingTime', {
      get: function get() {
        var time = this.get('request.meetingTime');
        return time.isValid();
      }
    }),
    textMonth: computed('request.meetingTime', {
      get: function get() {
        var month = null;
        var time = this.get('request.meetingTime');
        if (time) {
          month = moment(time).format('MMM');
        }
        return month;
      }
    }),
    textDayOfMonth: computed('request.meetingTime', {
      get: function get() {
        var date = null;
        var time = this.get('request.meetingTime');
        if (time) {
          date = moment(time).format('D');
        }
        return date;
      }
    }),

    iconLocation: 'map-marker', //  'phone' 'skype' 'hangout'
    textLocation: alias('request.locationText'),

    cardIsNew: false,
    cardIsDraft: alias('request.statusDraft'),
    cardIsResponded: alias('request.statusResponded'),
    cardNotWaiting: or('cardIsDraft', 'cardIsResponded', 'cardIsNew'),
    cardIsWaiting: not('cardNotWaiting'), // FTS. Waiting till you can computed(computed())
    cardIsExpired: computed('request.statusResponded', 'request.meetingTime', function () {
      var meetingTime = this.get('request.meetingTime');
      if (_ember['default'].isBlank(meetingTime)) {
        return false;
      }

      if (this.get('request.statusResponded')) {
        return moment().isAfter(meetingTime);
      } else {
        return false;
      }
    }),
    formatTimeText: function formatTimeText(tz) {
      var timeStart = moment(this.get('request.meetingStart')).tz(tz);
      if (!timeStart.isValid()) {
        return '—';
      }
      var formatString = 'h:mma, ddd D MMM'; //Localised, 'LT, ll'
      var now = this.get('now');
      if (!timeStart.isSame(now, 'year')) {
        formatString += ' YYYY';
      }
      if (this.get('showTimeZone')) {
        formatString += ' z';
      }
      var formattedStart = timeStart.format(formatString);
      return formattedStart;
    },

    actions: {
      cardClick: function cardClick() {
        if (this.get('cardIsDraft')) {
          this.sendAction('editClick', this.get('request.id'));
        } else {
          this.sendAction('cardClick', this.get('request.id'));
        }
      },
      resendClick: function resendClick() {
        this.sendAction('resendClick', this.get('request.id'));
      },
      cancelClick: function cancelClick() {
        this.sendAction('cancelClick', this.get('request.id'));
      },
      editClick: function editClick() {
        this.sendAction('editClick', this.get('request.id'));
      },
      hideClick: function hideClick() {
        this.sendAction('hideClick', this.get('request.id'));
      }
    }
  });
});