define('foogi-ember-client/pods/components/calendar-display/full/component', ['exports', 'ember', 'foogi-ember-client/constants'], function (exports, _ember, _foogiEmberClientConstants) {
  var FullWeekLength = _foogiEmberClientConstants['default'].FullWeekLength;
  var CondensedWeekLength = _foogiEmberClientConstants['default'].CondensedWeekLength;
  var MobileJumpLength = _foogiEmberClientConstants['default'].MobileJumpLength;
  var MobileMediaQuery = _foogiEmberClientConstants['default'].MobileMediaQuery;
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var alias = computed.alias;
  var deprecatingAlias = computed.deprecatingAlias;
  var equal = computed.equal;
  var and = computed.and;
  var not = computed.not;
  exports['default'] = Component.extend({

    // COMPONENT PROPERTIES
    classNames: ['calendar-display', 'desktop-calendar'],

    // Parameters
    request: null,
    timeRequest: deprecatingAlias('request'),
    calendar: null,
    accounts: null,
    mobile: false,
    justConnected: true,
    isReceiver: not('displayAsSender'),
    notAuthenticated: not('authenticated'),
    showConnectedSuccessfully: and('isReceiver', 'authenticated', 'justConnected'),

    // calendar: computed('request', function() {
    //   return this.get('request.metaCalendar').then((mc)=> {
    //     return mc;
    //   });
    // }),

    // Initial Values
    // initialView: alias('calendar.initialView'),
    // weekStarting: alias('calendar.initialView'),
    meetingTime: computed('request.meetingTime', 'tzTarget', {
      get: function get() {
        var meetingTime = this.get('request.meetingTime');
        var tzTarget = this.get('tzTarget');
        return moment(meetingTime).tz(tzTarget);
      },
      set: function set(time) {
        this.set('request.meetingTime', time);
      }
    }),
    activeWeekIndex: 0,

    // Display logic properties
    busyBlocksVisible: not('displayAsSender'),

    // PROPERTIES DIRECTLY TALKING TO THE MODEL
    tzSource: alias('calendar.tzSource'),
    tzTarget: alias('calendar.tzTarget'),
    displayTimezone: alias('tzTarget'),

    requestTimezone: deprecatingAlias('tzSource'),

    duration: alias("request.meetingDuration"),

    // availabilityDays: Ember.computed.alias('permissions.availabilityDays'),
    // availabilityTimes: Ember.computed.alias('permissions.availabilityTimes'),

    // COMPUTED PROPERTIES FOR DISPLAY PURPOSES
    // disableLeftControl: Ember.computed.equal('activeWeekIndex', 0),
    // disableRightControl: Ember.computed('activeWeekIndex', function(){
    //   var weeksLength = this.get('weeks').length;
    //   var activeWeekIndex = this.get('activeWeekIndex');
    //   return activeWeekIndex === weeksLength -1;
    // }),
    disableLeftControl: false,
    disableRightControl: false,

    showConnectModal: and('isReceiver', 'notAuthenticated'),

    daysInWeek: computed('mobile', function () {
      var mobile = this.get('mobile');
      return mobile ? CondensedWeekLength : FullWeekLength;
    }),

    threeWide: equal('daysInWeek', CondensedWeekLength),

    // weeks: computed('days', function() {
    //   Ember.Logger.debug("weeks");
    //   var weeks = new Ember.A();
    //   var days = Ember.copy(this.get('days'));
    //   while (days.length > 0) {
    //     weeks.pushObject(days.splice(0, this.get('daysInWeek')));
    //   }
    //   return weeks;
    // }),

    beginningOfDisplayWeek: computed('activeWeekIndex', 'calendar.now', 'daysInWeek', 'meetingTime', function () {

      var now = this.get('calendar.now');
      var weekOffset = this.get('activeWeekIndex');
      var daysInWeek = this.get('daysInWeek');
      var displayTimezone = this.get('tzTarget');
      var meetingTime = this.get('meetingTime');

      var thisWeek,
          displayWeek,
          meetingWeek = null;

      if (daysInWeek === FullWeekLength) {
        thisWeek = moment(now).tz(displayTimezone).startOf('week');
        meetingWeek = moment(meetingTime).tz(displayTimezone).startOf('week');
        if (meetingWeek > thisWeek) {
          displayWeek = meetingWeek.add(weekOffset, 'weeks');
        } else {
          displayWeek = thisWeek.add(weekOffset, 'weeks');
        }
      } else {
        thisWeek = moment(now).tz(displayTimezone);
        meetingWeek = moment(meetingTime).tz(displayTimezone);
        if (meetingWeek > thisWeek) {
          displayWeek = meetingWeek.add(weekOffset * MobileJumpLength, 'days');
        } else {
          displayWeek = thisWeek.add(weekOffset * MobileJumpLength, 'days');
        }
      }
      return displayWeek;
    }),

    activeWeek: computed('activeWeekIndex', 'daysInWeek', 'request', function () {

      var calendar = this.get('calendar');
      var daysInWeek = this.get('daysInWeek');
      var beginningOfWeek = this.get('beginningOfDisplayWeek');

      var week = new _ember['default'].A();

      for (var i = 0; i < daysInWeek; i++) {
        var date = moment(beginningOfWeek).add(i, 'days');
        week.pushObject(calendar.getDay(date));
      }

      return week;
    }),

    ///////////// NEED FIXING

    _getDiffBetween: function _getDiffBetween(start, end, period) {
      return start.diff(end, period);
    },

    _zoneTime: function _zoneTime(momentTime) {
      var displayTimezone = this.get('displayTimezone') ? this.get('displayTimezone') : jstz.determine().name();
      return moment(momentTime).tz(displayTimezone);
    },

    _toggleCalendarsDisplay: function _toggleCalendarsDisplay() {
      var calendars = this.get('calendars');
      calendars.forEach(function (calendar) {
        calendar.events.then(function (events) {
          events.forEach(function (event) {
            // Set event display
            event.set('display', calendar.display);
          });
        });
      });
    },

    _isValidMeetingTime: function _isValidMeetingTime(time, day) {
      // TODO Rewrite
      var result = true;
      // if droppedBlockRange does not overlap ranges of blocks on current Day allow it to be dropped
      var droppedBlockRange = moment().range(time, moment(time).add('minutes', this.get('duration')));
      // find the day the block has been dropped on
      var activeWeekDays = this.get('activeWeek');
      // TODO: take into account the meeting could be split across multiple days.

      // create a range for each busyBlock
      var busyBlocks = day.get('busyBlocks');
      var meetings = day.get('meetings');
      var unavailableBlocks = day.get('unavailableBlocks');

      // iterate through blocks checking ranges don't overlap.
      // if (unavailableBlocks){
      //   unavailableBlocks.forEach(function(block){
      //     if (droppedBlockRange.overlaps(moment().range(moment(block.get('timeStart')), moment(block.get('timeEnd'))))){
      //       result = false;
      //     }
      //   });
      // }

      // if (busyBlocks){
      //   busyBlocks.forEach(function(block){
      //     if (droppedBlockRange.overlaps(moment().range(moment(block.get('timeStart')), moment(block.get('timeEnd'))))){
      //       result = false;
      //     }
      //   });
      // }

      // if (meetings){
      //   meetings.forEach(function(block){
      //     if (droppedBlockRange.overlaps(moment().range(moment(block.get('timeStart')), moment(block.get('timeEnd'))))){
      //       result = false;
      //     }
      //   });
      // }
      return result;
    },

    handleResize: function handleResize() {
      var mobile = window.matchMedia(MobileMediaQuery).matches;
      this.set('mobile', mobile);
    },

    bindResizeEvent: (function () {
      this.handleResize();
      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.get('handleResize')));
    }).on('init'),

    actions: {

      placeMeeting: function placeMeeting(placedtime, day) {
        // TODO Rewrite
        _ember['default'].Logger.debug("block dropped");
        // check validity of placement
        if (this._isValidMeetingTime(placedtime, day)) {
          this.sendAction('setMeetingStart', placedtime);
          _ember['default'].Logger.debug('placedTime: ' + placedtime.toString());
          _ember['default'].Logger.debug('length: ' + this.get('duration'));
        }
      },

      toggleCalendar: function toggleCalendar(calendar, value) {
        this.sendAction('toggleCalendar', calendar, value);
      },

      jumpWeekNext: function jumpWeekNext() {
        var oldIdx = this.get('activeWeekIndex');
        _ember['default'].Logger.debug("Jumping to next week");
        this.set('activeWeekIndex', oldIdx + 1);
      },

      jumpWeekPrev: function jumpWeekPrev() {
        var oldIdx = this.get('activeWeekIndex');
        _ember['default'].Logger.debug("Jumping to prev week");
        this.set('activeWeekIndex', oldIdx - 1);
      },

      jumpToMeeting: function jumpToMeeting() {
        alert("jumpToMeeting NOT IMPLEMENTED");
        // var placedIndex = this.get('placedMeetingWeekIndex');
        // this.set('activeWeekIndex', placedIndex);
      },

      validate: function validate() {
        this.sendAction('validate');
      },

      back: function back() {
        this.sendAction('back');
      },

      authenticate: function authenticate(provider) {
        this.sendAction('authenticate', provider);
      },

      updateDuration: function updateDuration(value) {
        this.sendAction('updateDuration', value);
      },
      slideCalendarPanel: function slideCalendarPanel() {
        $('#calendar-options-slide-panel').slideToggle();
        this.toggleProperty('calendarOptionsOpen');
      },
      'continue': function _continue() {
        this.toggleProperty('justConnected');
      },
      showAuthPopup: function showAuthPopup() {
        _ember['default'].$('#walkThroughModal').click();
        _ember['default'].run.next(this, function () {
          _ember['default'].$('#auth-popup').dropdown('toggle');
        });
      }
    }

    ////////// End of the FIX LIST

  });
});
/* global moment */
/* global jstz */