define('foogi-ember-client/pods/user/model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    uuid: _emberData['default'].attr('string'),
    displayName: _emberData['default'].attr('string'),
    primaryEmail: _emberData['default'].attr('string'),
    registrationStatus: _emberData['default'].attr('string', { 'default': 'preferences' }),
    pictureUrl: _emberData['default'].attr('string'),
    timezone: _emberData['default'].attr('string'),
    defaultProfileCompleted: _emberData['default'].attr('boolean'),
    timeRequests: _emberData['default'].hasMany('timeRequest')
  });
});