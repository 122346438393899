define('foogi-ember-client/mixins/request-edit-return-to', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    isLoading: false,
    actions: {
      saveAndReturn: function saveAndReturn() {
        var _this = this;

        this.set('isLoading', true);
        var req = this.modelFor('request');

        req.save().then(function () {
          // success
          _this.send('backOnePage');
        }, function () {
          // fail
          _this.set('isLoading', false);
          _this.refresh();
        });
      },
      cancel: function cancel() {
        var req = this.modelFor('request');

        req.rollback();
        this.send('backOnePage');
      },
      jumpHome: function jumpHome() {
        this.transitionTo('index');
      }
    }
  });
});