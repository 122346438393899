define("foogi-ember-client/pods/application/serializer", ["exports", "active-model-adapter"], function (exports, _activeModelAdapter) {
  exports["default"] = _activeModelAdapter.ActiveModelSerializer.extend({
    typeForRoot: function typeForRoot(root) {
      // 'time-request-permissions' should become 'timeRequestPermissions' and
      // 'user-preferences' should become 'userPreferences'
      if (root === "time_request_permissions" || root === "user-preferences") {
        var toModify = this._super(root);
        // add the s as by default is made singular
        var modified = toModify + 's';
        return modified;
      } else {
        return this._super(root);
      }
    }
  });
});