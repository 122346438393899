define("foogi-ember-client/templates/components/loading-bars", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.6.2",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "foogi-ember-client/templates/components/loading-bars.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 32");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(2)");
        dom.setAttribute(el2, "d", "M0 12 V20 H4 V12z");
        var el3 = dom.createTextNode(" \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "d");
        dom.setAttribute(el3, "values", "M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z");
        dom.setAttribute(el3, "dur", "1.2s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0");
        dom.setAttribute(el3, "keytimes", "0;.2;.5;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(8)");
        dom.setAttribute(el2, "d", "M0 12 V20 H4 V12z");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "d");
        dom.setAttribute(el3, "values", "M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z");
        dom.setAttribute(el3, "dur", "1.2s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.2");
        dom.setAttribute(el3, "keytimes", "0;.2;.5;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(14)");
        dom.setAttribute(el2, "d", "M0 12 V20 H4 V12z");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "d");
        dom.setAttribute(el3, "values", "M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z");
        dom.setAttribute(el3, "dur", "1.2s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.4");
        dom.setAttribute(el3, "keytimes", "0;.2;.5;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(20)");
        dom.setAttribute(el2, "d", "M0 12 V20 H4 V12z");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "d");
        dom.setAttribute(el3, "values", "M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z");
        dom.setAttribute(el3, "dur", "1.2s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.6");
        dom.setAttribute(el3, "keytimes", "0;.2;.5;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "transform", "translate(26)");
        dom.setAttribute(el2, "d", "M0 12 V20 H4 V12z");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("animate");
        dom.setAttribute(el3, "attributeName", "d");
        dom.setAttribute(el3, "values", "M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z");
        dom.setAttribute(el3, "dur", "1.2s");
        dom.setAttribute(el3, "repeatCount", "indefinite");
        dom.setAttribute(el3, "begin", "0.8");
        dom.setAttribute(el3, "keytimes", "0;.2;.5;1");
        dom.setAttribute(el3, "keySplines", "0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8");
        dom.setAttribute(el3, "calcMode", "spline");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'width');
        morphs[1] = dom.createAttrMorph(element0, 'height');
        morphs[2] = dom.createAttrMorph(element0, 'fill');
        return morphs;
      },
      statements: [["attribute", "width", ["get", "loadingSvgSize", ["loc", [null, [1, 68], [1, 82]]]]], ["attribute", "height", ["get", "loadingSvgSize", ["loc", [null, [1, 94], [1, 108]]]]], ["attribute", "fill", ["get", "loadingSvgColor", ["loc", [null, [1, 118], [1, 133]]]]]],
      locals: [],
      templates: []
    };
  })());
});