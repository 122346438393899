define('foogi-ember-client/pods/components/dashboard-card-pack/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: 'dashboard',
    actions: {
      cancel: function cancel(token) {
        this.sendAction('cancelRequest', token);
      },
      resend: function resend(token) {
        this.sendAction('resend', token);
      },
      edit: function edit(token) {
        this.sendAction('edit', token);
      },
      show: function show(token) {
        this.sendAction('show', token);
      },
      hide: function hide(token) {
        this.sendAction('hide', token);
      },
      createRequest: function createRequest() {
        this.sendAction('createRequest');
      }
    }
  });
});