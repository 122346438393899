define('foogi-ember-client/pods/components/sidebar-card-meeting-details/component', ['exports', 'ember', 'foogi-ember-client/mixins/utility-functions'], function (exports, _ember, _foogiEmberClientMixinsUtilityFunctions) {
  exports['default'] = _ember['default'].Component.extend(_foogiEmberClientMixinsUtilityFunctions['default'], {
    classNames: ['sidebar-panel'],
    mobile: null,
    meetingBlockFloating: null,
    meetingStart: null,
    meetingSummary: null,
    locName: null,
    locAddress: null,
    duration: null,
    displayTimezone: null,
    timezoneSender: null,
    timezoneReceiver: null,
    meetingEnd: null,
    hostName: null,
    receiverDisplayName: null,
    receiverEmail: null,
    placedTimeCoOrds: null,
    meetingOnline: null,
    activeWeek: null,
    activeWeekRange: _ember['default'].computed('activeWeek', function () {
      var activeWeek = this.get('activeWeek');
      if (_ember['default'].isEmpty(activeWeek)) {
        console.log("activeWeek not yet set");
        return;
      }
      return moment().range(moment(activeWeek.get('firstObject.date')).startOf('day'), moment(activeWeek.get('lastObject.date')).endOf('day'));
    }),

    // Receiver's timezone
    receiverMeetingStart: _ember['default'].computed('meetingStart', 'timezoneReceiver', function () {
      return this.formatTimeInTz('meetingStart', 'timezoneReceiver', 'LT');
    }),
    receiverDate: _ember['default'].computed('meetingStart', 'timezoneReceiver', function () {
      return this.formatTimeInTz('meetingStart', 'timezoneReceiver', 'ddd D');
    }),

    // Sender's timezone
    senderMeetingStart: _ember['default'].computed('meetingStart', 'timezoneSender', function () {
      return this.formatTimeInTz('meetingStart', 'timezoneSender', 'LT');
    }),
    senderDate: _ember['default'].computed('meetingStart', 'timezoneSender', function () {
      return this.formatTimeInTz('meetingStart', 'timezoneSender', 'ddd D');
    }),

    // Display timezone set to target timezone
    displayMeetingStart: _ember['default'].computed('meetingStart', 'displayTimezone', function () {
      return this.formatTimeInTz('meetingStart', 'displayTimezone', 'LT');
    }),
    displayDate: _ember['default'].computed('meetingStart', 'displayTimezone', function () {
      return this.formatTimeInTz('meetingStart', 'displayTimezone', 'ddd D');
    }),

    timeSelected: _ember['default'].computed('meetingStart', function () {
      var time = this.get('meetingStart');
      return time.isValid();
    }),

    actions: {
      confirm: function confirm() {
        this.sendAction('confirm');
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      prev: function prev() {
        this.sendAction('prev');
      },
      toggleDetails: function toggleDetails() {
        $('.meeting-details-card').toggleClass("show-mobile-details");
        this.toggleProperty('showMobileDetails');
      },
      updateDuration: function updateDuration(value) {
        this.sendAction('updateDuration', value);
      }
    }

  });
});
/* global moment */